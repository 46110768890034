import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Fab, Stack } from "@mui/material";
import SectionHeader from "../common/SectionHeader";
import OpeningHours from "./OpeningHours";
import Schedule from "./Schedule";

function Departures() {
  const { t } = useTranslation();
  return (
    <Container
      id="departures"
      sx={{
        py: { xs: 10, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: {sx: "left", sm: "center"},
        gap: 3,
      }}
    >
      <SectionHeader
        chip={"departureChip"}
        title={"departureTitle"}
        text={"departureText"}
      />
      <Stack alignItems={{sx: "left", sm: "center"}}>
        <Typography variant="subtitle1" color="text.primary">
          {t("departureText2")}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: { xs: 2, sm: 4 } }}
        >
          Kungsholmen: 00, 20, 40 <br />
          Södermalm: 10, 30, 50
        </Typography>
      </Stack>
      <Stack alignItems={"center"} gap={10}>
      <Schedule />
      <OpeningHours showButton={false}/>
      </Stack>
    </Container>
  );
}

export default Departures;
