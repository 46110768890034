import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import FAQ from '../components/FAQ';

function FAQScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
      <title>Zeam | FAQ</title>
      <meta
        name="description"
        content="Here you will find the most common questions and answers about our service."
      />
    </Helmet>
    <FAQ />
  </>
);
}

export default FAQScreen