import React, { useEffect } from "react";
import { Box } from "@mui/material";

function SurveyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      height="70vh"
      width="100%"
      mt={"120px"}
    >
      <iframe
        width="100%"
        height="100%"
        src="https://docs.google.com/forms/d/e/1FAIpQLSffi8vIOzkaZpvf-VghLg3pkKYvNBOdQh7me5_wP5Ia_BjvVw/viewform?embedded=true"
        title="Google forms iframe"
        style={{border: 0}}
      >
        Laddar formulär...
      </iframe>
    </Box>
  );
}

export default SurveyPage;
