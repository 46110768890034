import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import About from "./screens/About";
import Departures from "./screens/Departures";
import User from "./screens/User";
import Privacy from "./screens/Privacy";
import Missing from "./screens/Missing";
import Price from "./screens/Price";
import Admin from "./screens/Admin";
import Subscribe from "./screens/Subscribe";
import NorthScreen from "./screens/NorthScreen";
import SouthScreen from "./screens/SouthScreen";
import Footer from "./components/footer";
import Header from "./components/header";
import ScrollToTop from "./components/common/ScrollToTop";
import FAQScreen from "./screens/FAQ";
import TagManager from "react-gtm-module";
import OnboardScreen from "./screens/OnboardScreen";
import Success from "./screens/Success";
import FindScreen from "./screens/FindUs";
import { useState } from "react";
import SurveyPage from "./screens/Survey";

export const gray = {
  50: "#FBFCFE",
  100: "#EAF0F5",
  200: "#D6E2EB",
  300: "#BFCCD9",
  400: "#94A6B8",
  500: "#5B6B7C",
  600: "#4C5967",
  700: "#364049",
  800: "#131B20",
  900: "#090E10",
};

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const tagManagerArgs = {
    gtmId: "GTM-TGJK6Q2H",
  };

  TagManager.initialize(tagManagerArgs);

  let theme = createTheme({
    palette: {
      primary: {
        main: "#203236",
        secondary: "#046c5a",
      },
      secondary: {
        main: "#ffd6dd",
        secondary: "#e0f5e3",
      },
      text: {
        primary: gray[800],
        secondary: gray[600],
      },
      grey: {
        50: gray[50],
        100: gray[100],
        200: gray[200],
        300: gray[300],
        400: gray[400],
        500: gray[500],
        600: gray[600],
        700: gray[700],
        800: gray[800],
        900: gray[900],
      },
    },
    typography: {
      fontFamily: "Figtree",
      body1: {
        fontSize: 18,
      },
      subtitle1: {
        fontSize: 19,
        fontWeight: "bold",
      },

      // allVariants: {
      //   fontFamily: "sans-serif",
      //   //color: "#203236",
      // },
      button: {
        textTransform: "none",
      },
      headTitle: {
        fontSize: 64,
      },
      headTitleEn: {
        fontSize: 48,
      },
      mainTitle: {
        fontSize: 200,
      },
      mainTitle2: {
        fontSize: 100,
      },
      mainTitleEn: {
        fontSize: 40,
      },
      slideTitle: {
        fontSize: 48,
      },
      slideText: {
        fontSize: 32,
      },
      ferryTitle: {
        fontSize: 32,
      },
      ferryText: {
        fontSize: 24,
      },
      alertSe: {
        fontSize: 40,
      },
      alertEn: {
        fontSize: 40,
      },
    },
  });

  theme = responsiveFontSizes(theme);
  console.log(headerHeight);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        {window.location.pathname.includes("/screens") ? null : (
          <Header setHeaderHeight={setHeaderHeight} />
        )}
        <div style={{ paddingTop: headerHeight }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/departures" element={<Departures />} />
            <Route path="/ticket" element={<Price />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/find-us" element={<FindScreen />} />
            <Route path="/faq" element={<FAQScreen />} />
            <Route path="/user" element={<User />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/survey" element={<SurveyPage />} />
            <Route path="/screens/north" element={<NorthScreen />} />
            <Route path="/screens/south" element={<SouthScreen />} />
            <Route path="/screens/onboard" element={<OnboardScreen />} />
            <Route path="/admin/success" element={<Success />} />
            <Route path="*" element={<Missing />} />
          </Routes>
        </div>
        <ScrollToTop />
        {window.location.pathname.includes("/screens") ? null : <Footer />}
      </ThemeProvider>
    </Router>
  );
}

export default App;
