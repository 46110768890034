import React from "react";
import { ReactComponent as ZeamWater } from "../../assets/zeamWater.svg";
import { Box, Typography } from "@mui/material";
import ReactPlayer from "react-player/youtube";


function BottomPart() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={"space-evenly"}
      sx={{ height: "55vh", bgcolor: "#ffd6dd" }}
      height="100%"
    >
      <ReactPlayer
        //url="https://youtu.be/YJK33ncifgM"
        url="https://youtu.be/NrjdreZobw8"
        playing={true}
        loop={true}
        volume={0}
        width={"100%"}
        height={608}
      />
      <Typography variant="headTitleEn" sx={{pt: 7}}>Läs mer på www.zeam.se</Typography>
      <ZeamWater style={{ width: 500 }} />
    </Box>
  );
}

export default BottomPart;
