import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ArrowDropDownLineIcon from "remixicon-react/ArrowDropDownLineIcon";
import { useState } from "react";
import SectionHeader from "../common/SectionHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AccordionActions, Button } from "@mui/material";
import NotificationIcon from "remixicon-react/NotificationLineIcon";
import { motion } from "framer-motion";

export default function FAQ() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let navigate = useNavigate();

  const navigateTicket = () => {
    navigate("/ticket");
  };

  const navigateUser = () => {
    navigate("/user");
  };

  const navigateSubscribe = () => {
    navigate("/subscribe");
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container
      id="faq"
      sx={{
        py: { xs: 10, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <SectionHeader chip={"faqChip"} title={"faqTitle"} text={"faqText"} />
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: -10,
          },

          visible: {
            opacity: 1,
            y: 0,
          },
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.1 }}
        viewport={{ once: true }}
      >
        <Box sx={{ width: "100%" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ArrowDropDownLineIcon />}>
              <Typography component="h3" variant="subtitle1">
                {t("q1")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                gutterBottom
                color="text.secondary"
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {t("a1")}
              </Typography>
            </AccordionDetails>
            <AccordionActions sx={{ justifyContent: "start" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={navigateTicket}
                sx={{ borderRadius: 10 }}
              >
                {t("menuTickets")}
              </Button>
            </AccordionActions>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ArrowDropDownLineIcon />}>
              <Typography component="h3" variant="subtitle1">
                {t("q2")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                gutterBottom
                color="text.secondary"
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {t("a2")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ArrowDropDownLineIcon />}>
              <Typography component="h3" variant="subtitle1">
                {t("q3")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                gutterBottom
                color="text.secondary"
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {t("a3")}
              </Typography>
            </AccordionDetails>
            <AccordionActions sx={{ justifyContent: "start" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={navigateUser}
                sx={{ borderRadius: 10 }}
              >
                {t("menuUser")}
              </Button>
            </AccordionActions>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary expandIcon={<ArrowDropDownLineIcon />}>
              <Typography component="h3" variant="subtitle1">
                {t("q4")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                gutterBottom
                color="text.secondary"
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {t("a4")}
              </Typography>
            </AccordionDetails>
            <AccordionActions sx={{ justifyContent: "start" }}>
              <Button
                onClick={navigateSubscribe}
                variant="contained"
                color="primary"
                startIcon={<NotificationIcon />}
                sx={{ flexShrink: 0, borderRadius: 10 }}
              >
                {t("subscribeButton")}
              </Button>
            </AccordionActions>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary expandIcon={<ArrowDropDownLineIcon />}>
              <Typography component="h3" variant="subtitle1">
                {t("q5")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                gutterBottom
                color="text.secondary"
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {t("a5")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </motion.div>
    </Container>
  );
}
