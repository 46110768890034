import React, { useEffect, useState } from "react";
import CustomTabPanel from "../CustomTabPanel";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import getStoppage from "../../functions/getStoppage";
import Axios from "axios";

function MessageTab({ value }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    text: "",
    success: false,
    show: false,
  });
  const [stoppage, setStoppage] = useState({
    stoppage: false,
    messageEn: "",
    messageSv: "",
    severity: "error",
  });
  const { origin } = window.location;

  useEffect(() => {
    getStoppage(setStoppage);
  }, []);

  const updateStoppage = () => {
    setLoading(true);
    Axios({
      method: "POST",
      url: `${origin}/update`,
      data: stoppage,
    })
      .then((res) => {
        setLoading(false);
        console.log(res);
        res.status === 200
          ? setAlert({
              text: "Meddelandet har uppdaterats",
              success: true,
              show: true,
            })
          : setAlert({
              text: "Något gick fel. Försök igen.",
              success: false,
              show: true,
            });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          text: "Något gick fel. Försök igen.",
          success: false,
          show: true,
        });
        console.log(err.response);
      });
  };

  const handleStoppage = (event) => {
    event.preventDefault();
    updateStoppage();
    console.log(stoppage);
  };

  return (
    <CustomTabPanel value={value} index={0}>
      <Stack direction="column" alignItems="start" gap={1}>
        <Typography variant="body1" sx={{ maxWidth: "lg" }}>
          Här kan du välja om ett meddelande ska synas på hemsidan och skärmarna
          på båthållplatserna. Om knappen är avstängd visas inget meddelande.
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: "lg" }}>
          För att visa meddelande - tryck på knappen, välj vad som ska stå i
          rutorna nedanför och tryck "Uppdatera".
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: "lg" }}>
          Sista rutan väljer vilken färg meddelandet ska ha vilket ska spegla
          hur allvarligt meddelandet är.
        </Typography>
        <form onSubmit={handleStoppage} autoComplete="off">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="center"
            sx={{ width: { xs: "80vw", md: "30vw" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={stoppage.stoppage}
                  onChange={() => {
                    stoppage.stoppage
                      ? setStoppage({ ...stoppage, stoppage: false })
                      : setStoppage({ ...stoppage, stoppage: true });
                  }}
                />
              }
              label="Visa meddelande?"
              sx={{ marginY: 2 }}
            />

            <TextField
              required
              helperText="Meddelandet på svenska"
              fullWidth
              multiline
              value={stoppage.messageSv}
              disabled={!stoppage.stoppage}
              margin="normal"
              onChange={(event) => {
                setStoppage({ ...stoppage, messageSv: event.target.value });
              }}
            />
            <TextField
              required
              helperText="Meddelandet på engelska"
              multiline
              value={stoppage.messageEn}
              fullWidth
              disabled={!stoppage.stoppage}
              margin="normal"
              onChange={(event) => {
                setStoppage({ ...stoppage, messageEn: event.target.value });
              }}
            />
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                disabled={!stoppage.stoppage}
                sx={{ marginY: 2 }}
              >
                <InputLabel>Allvarlighet</InputLabel>
                <Select
                  value={stoppage.severity}
                  onChange={(event) => {
                    setStoppage({
                      ...stoppage,
                      severity: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={"info"}>Blå</MenuItem>
                  <MenuItem value={"warning"}>Gul</MenuItem>
                  <MenuItem value={"error"}>Röd</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{ borderRadius: 10 }}
              type="submit"
            >
              Uppdatera
            </Button>
            {loading ? <CircularProgress /> : <div></div>}
          </Box>
        </form>
      </Stack>
      <Snackbar
        open={alert.show}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({
            text: "",
            success: false,
            show: false,
          });
        }}
      >
        <Alert
          onClose={() => {
            setAlert({
              text: "",
              success: false,
              show: false,
            });
          }}
          severity={alert.success ? "success" : "error"}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </CustomTabPanel>
  );
}

export default MessageTab;
