import React from "react";
import ReactPlayer from "react-player/youtube";
import { Box } from "@mui/material";

function OnboardScreen() {
  return (
    <Box sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
      <ReactPlayer
        // url="https://youtu.be/YJK33ncifgM"
        url="https://youtu.be/NrjdreZobw8"
        playing={true}
        loop={true}
        volume={0}
        width="100%"
        height="100%"
      />
    </Box>
  );
}

export default OnboardScreen;
