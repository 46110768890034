function GetMinutesUntilDeparture(newSchedule, side) {
    let now = new Date();
    let today = now.getDay();
    let currentMinutes = now.getHours() * 60 + now.getMinutes(); // Current time in minutes
  
    // Get today's schedule
    const scheduleValues = Object.values(newSchedule);
    let todaysSchedule = scheduleValues[today];
    var result = todaysSchedule.filter((obj) => {
      return obj.from === side;
    });
    const departures = result.map(({ departure }) => {
      // Convert departure times to minutes
      let [hour, minute] = departure.split(":").map(Number);
      return hour * 60 + minute;
    });
  
    // Get tomorrow's schedule
    let tomorrowSchedule = scheduleValues[today + 1] ? scheduleValues[today + 1] : scheduleValues[0];
    var resultTomorrow = tomorrowSchedule.filter((obj) => {
      return obj.from === side;
    });
    const departuresTomorrow = resultTomorrow.map(({ departure }) => {
      // Convert departure times to minutes
      let [hour, minute] = departure.split(":").map(Number);
      return hour * 60 + minute;
    });
  
    // Find the next departure
    let nextDepartureMinutes = null;
    for (let i = 0; i < departures.length; i++) {
      if (departures[i] >= currentMinutes) {
        nextDepartureMinutes = departures[i];
        break;
      }
    }
  
    // If no departure found today, take the first departure from tomorrow
    if (nextDepartureMinutes === null && departuresTomorrow.length > 0) {
      nextDepartureMinutes = departuresTomorrow[0] + 24 * 60; // Add 24 hours since it's tomorrow
    }
  
    // Calculate minutes until next departure
    let minutesUntilNextDeparture = nextDepartureMinutes !== null ? nextDepartureMinutes - currentMinutes : null;
  
    // Adjust for crossing midnight when calculating for tomorrow's schedule
    if (minutesUntilNextDeparture < 0) {
      minutesUntilNextDeparture += 24 * 60; // Add 24 hours
    }
  
    return minutesUntilNextDeparture;
  }

  export default GetMinutesUntilDeparture