import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import Axios from "axios";
import { gray } from "../../App";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  //"pk_test_51LmFDuIfGTG7lfEnCPLBf1ntR5WEchYQJGa0Koc3NMASfzNchTDQ1BV6dVONi77fv0t9W6rMyWqXto8zEqHqYNE1007NzusGEG"
  "pk_live_51LmFDuIfGTG7lfEnkkFFYxmFd7rhqUlkhNhTBzh2IyEpKUxsYygfsOCETh55dU1bQxlVbuMLIGCNx2CXzUrV9mBp00GwtUjJQw"
);
const { origin } = window.location;
const isSuccess = window.location == `${origin}/ticket?success` ? true : false;

function Checkout() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [tickets, setTickets] = useState({
    adult: 0,
    youth: 0,
    student: 0,
    senior: 0,
  });
  const [periodTickets, setPeriodTickets] = useState({
    "30dayAdult": 0,
    "30dayDiscount": 0,
  });
  const [stripeOptions, setStripeOptions] = useState(null);
  const [isPeriodTickets, setIsPeriodTickets] = useState(false);
  const prices = { adult: 35, youth: 20, student: 20, senior: 20 };
  const periodPrices = { adult30: 900, discount30: 590 };
  let navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setActiveStep(2);
    }
  }, []);

  const steps = [
    {
      label: t("checkoutStep1"),
      description: t("checkoutDescription1"),
    },
    {
      label: t("checkoutStep2"),
      description: t("checkoutDescription2"),
    },
    {
      label: t("checkoutStep3"),
      description: t("checkoutDescription3"),
    },
  ];

  const incrementTicket = (type) => {
    setTickets((prevTickets) => ({
      ...prevTickets,
      [type]: prevTickets[type] + 1,
    }));
  };

  const decrementTicket = (type) => {
    setTickets((prevTickets) => ({
      ...prevTickets,
      [type]: prevTickets[type] > 0 ? prevTickets[type] - 1 : 0,
    }));
  };

  const incrementPeriodTicket = (type) => {
    setPeriodTickets((prevTickets) => ({
      ...prevTickets,
      [type]: prevTickets[type] + 1,
    }));
  };

  const decrementPeriodTicket = (type) => {
    setPeriodTickets((prevTickets) => ({
      ...prevTickets,
      [type]: prevTickets[type] > 0 ? prevTickets[type] - 1 : 0,
    }));
  };

  const totalPrice =
    tickets.adult * prices.adult +
    tickets.youth * prices.youth +
    tickets.student * prices.student +
    tickets.senior * prices.senior;

  const totalPeriodPrice =
    periodTickets["30dayAdult"] * periodPrices.adult30 +
    periodTickets["30dayDiscount"] * periodPrices.discount30;

  const getTickets = () => {
    setActiveStep(1);
    const fetchClientSecret = async () => {
      return Axios({
        method: "POST",
        url: `${origin}/create-checkout-session`,
        headers: {
          "Accept-Language": navigator.language,
        },
        data: tickets,
      }).then((res) => res.data.clientSecret);
    };

    const options = { fetchClientSecret };
    setStripeOptions(options);
  };

  const getPeriodTickets = () => {
    setActiveStep(1);
    const fetchClientSecret = async () => {
      return Axios({
        method: "POST",
        url: `${origin}/create-period-checkout-session`,
        headers: {
          "Accept-Language": navigator.language,
        },
        data: periodTickets,
      }).then((res) => res.data.clientSecret);
    };

    const options = { fetchClientSecret };
    setStripeOptions(options);
  };

  const ticketOptions = [
    { type: "adult", label: t("checkoutTicket1") },
    { type: "youth", label: t("checkoutTicket2") },
    { type: "student", label: t("checkoutTicket3") },
    { type: "senior", label: t("checkoutTicket4") },
  ];

  const periodTicketOptions = [
    { type: "30dayAdult", label: t("checkoutPeriodTicket1") },
    { type: "30dayDiscount", label: t("checkoutPeriodTicket2") },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "80vw",
        p: { xs: 2, sm: 3 },
        my: 3,
        border: `1px solid ${alpha(gray[200], 0.8)}`,
        borderRadius: 10,
        background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        display="flex"
        justifyContent="start"
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {index === 0 && (
                <Box>
                  {isPeriodTickets ? (
                    <div>
                      <Typography
                        color="text.secondary"
                        variant="body1"
                        sx={{ mb: 5 }}
                      >
                        {t("checkoutDescription13")}
                        <Typography sx={{ mt: 2 }}>
                          {t("checkoutDescription12")}
                        </Typography>
                      </Typography>
                      {periodTicketOptions.map((ticket) => (
                        <Stack
                          key={ticket.type}
                          direction={isMobile ? "column" : "row"}
                          width={400}
                          maxWidth={"70vw"}
                          justifyContent="space-between"
                          mb={3}
                          alignItems={isMobile ? "center" : "flex-start"}
                        >
                          <Typography>{ticket.label}</Typography>
                          <ButtonGroup variant="contained">
                            <Button
                              onClick={() => decrementPeriodTicket(ticket.type)}
                              size="large"
                            >
                              -
                            </Button>
                            <Button
                              variant="outlined"
                              disableRipple
                              size="large"
                            >
                              {periodTickets[ticket.type]}
                            </Button>
                            <Button
                              onClick={() => incrementPeriodTicket(ticket.type)}
                              size="large"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      ))}
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          justifyContent: isMobile ? "center" : "flex-start",
                          mt: 5,
                        }}
                      >
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          disabled={totalPeriodPrice === 0}
                          sx={{ borderRadius: 10 }}
                          onClick={getPeriodTickets}
                        >
                          {t("checkoutButton")} {totalPeriodPrice} kr
                        </Button>
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          sx={{ borderRadius: 10 }}
                          onClick={() => setIsPeriodTickets(false)}
                        >
                          {t("checkoutButtonSingle")}
                        </Button>
                      </Box>
                    </div>
                  ) : (
                    <div>
                      <Typography
                        color="text.secondary"
                        variant="body1"
                        sx={{ mb: 5 }}
                      >
                        {step.description}
                        <Typography sx={{ mt: 2 }}>
                          {t("checkoutDescription12")}
                        </Typography>
                      </Typography>
                      {ticketOptions.map((ticket) => (
                        <Stack
                          key={ticket.type}
                          direction={isMobile ? "column" : "row"}
                          width={400}
                          maxWidth={"70vw"}
                          justifyContent="space-between"
                          mb={3}
                          alignItems={isMobile ? "center" : "flex-start"}
                        >
                          <Typography>{ticket.label}</Typography>
                          <ButtonGroup variant="contained">
                            <Button
                              onClick={() => decrementTicket(ticket.type)}
                              size="large"
                            >
                              -
                            </Button>
                            <Button
                              variant="outlined"
                              disableRipple
                              size="large"
                            >
                              {tickets[ticket.type]}
                            </Button>
                            <Button
                              onClick={() => incrementTicket(ticket.type)}
                              size="large"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      ))}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: isMobile ? "center" : "flex-start",
                          mt: 5,
                          gap: 3,
                        }}
                      >
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          disabled={totalPrice === 0}
                          sx={{ borderRadius: 10 }}
                          onClick={getTickets}
                        >
                          {t("checkoutButton")} {totalPrice} kr
                        </Button>
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          sx={{ borderRadius: 10 }}
                          onClick={() => setIsPeriodTickets(true)}
                        >
                          {t("checkoutButtonPeriod")}
                        </Button>
                      </Box>
                    </div>
                  )}
                </Box>
              )}

              {index === 1 && (
                <Box>
                  <Typography
                    color="text.secondary"
                    variant="body1"
                    sx={{ mb: 5 }}
                  >
                    {step.description}
                  </Typography>
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={stripeOptions}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{ borderRadius: 10, mt: 2 }}
                    onClick={() => setActiveStep(0)}
                  >
                    {t("checkoutStep2Button")}
                  </Button>
                </Box>
              )}

              {index === 2 && (
                <Box>
                  <Typography
                    color="text.secondary"
                    variant="body1"
                    sx={{ mb: 5 }}
                  >
                    {step.description}
                  </Typography>
                  <Typography
                    color="primary.secondary"
                    variant="body1"
                    fontWeight={"bold"}
                  >
                    {t("surveyText")}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{ borderRadius: 10, mt: 1 }}
                    onClick={() => navigate("/survey")}
                  >
                    {t("surveyButton")}
                  </Button>
                </Box>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default Checkout;
