import React from "react";
import { Box, Typography, Alert, Snackbar, Stack } from "@mui/material";
import { ReactComponent as ShipLine } from "../../assets/shipLine.svg";
import { ReactComponent as ZimonSad } from "../../assets/zimonSad.svg";

function TopPart({ stoppage, setStoppage, departure, goingTo }) {
  return (
    <Box
      height={"45vh"}
      width={"100vw"}
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      bgcolor="secondary.main"
    >
      {departure === "tomorrow" ? (
        <>
          <Typography
            color="primary.main"
            sx={{ mt: "5vh" }}
            variant="headTitle"
            align="center"
          >
            Nästa färja till {goingTo} går
          </Typography>
          <Typography
            color="primary.main"
            sx={{ mt: 1, color: "#046c5a" }}
            variant="headTitleEn"
            align="center"
          >
            Next ferry to {goingTo} departs
          </Typography>
          <Typography
            color="primary.main"
            sx={{ my: "4vh" }}
            variant="mainTitle"
            fontWeight="bold"
          >
            Imorgon / Tomorrow
          </Typography>
          <ShipLine />
        </>
      ) : (
        <>
          <Typography
            color="primary.main"
            sx={{ mt: "5vh" }}
            variant="headTitle"
            align="center"
          >
            Nästa färja till {goingTo} går om
          </Typography>
          <Typography
            color="primary.main"
            sx={{ mt: 1, color: "#046c5a" }}
            variant="headTitleEn"
            align="center"
          >
            Next ferry to {goingTo} departs in
          </Typography>
          <Typography
            color="primary.main"
            sx={{ my: "4vh" }}
            variant="mainTitle"
            fontWeight="bold"
          >
            {departure} min
          </Typography>
          <ShipLine />
        </>
      )}

      <Snackbar
        open={stoppage.stoppage}
        autoHideDuration={null}
        onClose={() => {
          setStoppage({ ...stoppage, stoppage: false });
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ width: "85vw", height: "40vh" }}
      >
        <Alert
          onClose={() => {
            setStoppage({ ...stoppage, stoppage: false });
          }}
          severity={stoppage.severity}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
            width: "90vw",
            fontSize: 40,
            "& .MuiAlert-icon": {
              fontSize: 90,
            },
          }}
        >
          <Stack
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography color="primary.main" variant="alertSe" align="center">
              {stoppage.messageSv}
            </Typography>
            {stoppage.severity === "error" ? (
              <ZimonSad style={{ width: "25vw" }} />
            ) : (
              <div style={{ marginTop: "1vh" }}></div>
            )}
            <Typography color="#046c5a" variant="alertEn" align="center">
              {stoppage.messageEn}
            </Typography>
          </Stack>
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default TopPart;
