import React, { useEffect } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ZimonHappy } from "../assets/zimon_happy_peony.svg";

function Success() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    let navigate = useNavigate();
  
    const navigateHome = () => {
      navigate("/");
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return (
      <Box>
        <Box
          minHeight={"90vh"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ZimonHappy
            style={{
              width: isMobile ? "50vw" : "30vw",
              height: isMobile ? "20vh" : "30vh",
            }}
          />
          <Typography variant="h5" fontWeight="light">
            Betalning godkänd!
          </Typography>
          <Typography>
            Ett mail med ett kvitto kommer skickas till din mailadress. Kvittot fungerar även om biljett. Trevlig resa!
          </Typography>
          <Button
            onClick={navigateHome}
            variant="contained"
            size="large"
            color="primary"
            sx={{ borderRadius: 10, mt: 5 }}
          >
            Gå till startsidan
          </Button>
        </Box>
      </Box>
    );
  }

export default Success