import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "remixicon-react/MenuLineIcon";
import {
  Alert,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import UserLineIcon from "remixicon-react/UserLineIcon";
import GlobalLineIcon from "remixicon-react/GlobalLineIcon";
import getStoppage from "../../functions/getStoppage";

const logoStyle = {
  width: "175px",
  height: "auto",
  cursor: "pointer",
};

const mWater = require("../../assets/M_Water.png");
const zeamWater = require("../../assets/Zeam_Water.png");

function Header({ setHeaderHeight }) {
  const headerRef = useRef(null);
  const [isHome, setIsHome] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { i18n, t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const [stoppage, setStoppage] = useState({
    stoppage: false,
    messageEn: "",
    messageSv: "",
    severity: "error",
  });

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const savedLang = localStorage.getItem("language");
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    } else {
      const defaultLang = navigator.language.startsWith("sv") ? "sv" : "en";
      i18n.changeLanguage(defaultLang);
      localStorage.setItem("language", defaultLang);
    }
  }, [i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location]);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      } else {
        setHeaderHeight(0);
      }
    };

    updateHeaderHeight();

    window.addEventListener("resize", updateHeaderHeight);

    return () => {
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, [setHeaderHeight]);

  useEffect(() => {
    getStoppage(setStoppage);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
        }}
      >
        <Toolbar
          variant="regular"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            bgcolor: isHome
              ? "rgba(255, 255, 255, 0.6)"
              : "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <Container>
            <Stack
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                ml: "-18px",
                px: 0,
              }}
            >
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <img
                  src={zeamWater}
                  style={logoStyle}
                  alt="logo of Zeam"
                  onClick={() => navigate("/")}
                />

                <MenuItem
                  onClick={() => navigate("/departures")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body1" color="text.primary">
                    {t("menuDepartures")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/ticket")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body1" color="text.primary">
                    {t("menuTickets")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/find-us")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body1" color="text.primary">
                    {t("menuFind")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/about")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body1" color="text.primary">
                    {t("menuAbout")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/contact")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body1" color="text.primary">
                    {t("menuContact")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/faq")}
                  href="/faq"
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body1" color="text.primary">
                    {t("faqChip")}
                  </Typography>
                </MenuItem>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 3,
                  alignItems: "start",
                }}
              >
                <Button
                  variant="text"
                  endIcon={<GlobalLineIcon />}
                  size="large"
                  color="primary"
                  onClick={handleClick}
                >
                  {t("menuLanguage")}
                </Button>
                <Button
                  variant="contained"
                  endIcon={<UserLineIcon />}
                  size="large"
                  onClick={() => navigate("/user")}
                  color="primary"
                  sx={{ borderRadius: 10 }}
                >
                  {t("menuUser")}
                </Button>
              </Box>
            </Stack>

            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Stack
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  ml: "-18px",
                  px: 0,
                }}
              >
                <img
                  src={mWater}
                  alt="app"
                  onClick={() => navigate("/")}
                  style={{ width: "85px", height: "auto", cursor: "pointer" }}
                />
                <Button
                  variant="text"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ minWidth: "30px", p: "4px" }}
                >
                  <MenuIcon />
                </Button>
              </Stack>
              <Drawer
                anchor="right"
                open={openDrawer}
                onClose={toggleDrawer(false)}
              >
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  ></Box>
                  <MenuItem>
                    <img
                      src={zeamWater}
                      style={logoStyle}
                      alt="logo of Zeam"
                      onClick={() => navigate("/")}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/departures")}>
                    {t("menuDepartures")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/ticket")}>
                    {t("menuTickets")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/find-us")}>
                    {t("menuFind")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/about")}>
                    {t("menuAbout")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/contact")}>
                    {t("menuContact")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/faq")}>
                    {t("faqChip")}
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<UserLineIcon />}
                      onClick={() => navigate("/user")}
                      sx={{ width: "100%" }}
                    >
                      {t("menuUser")}
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      endIcon={<GlobalLineIcon />}
                      onClick={handleClick}
                      sx={{ width: "100%" }}
                    >
                      {t("menuLanguage")}
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Container>
        </Toolbar>
        {stoppage.stoppage && (
          <Alert severity={stoppage.severity} onClose={() => {setStoppage(stoppage.stoppage === false)}} ref={headerRef} sx={{opacity: 0.97}}>
            <Typography color="primary.main">{stoppage.messageSv}</Typography>
            <Typography color="#046c5a">{stoppage.messageEn}</Typography>
          </Alert>
        )}
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLanguageChange("sv")}>
              <ListItemText primary="🇸🇪 Svenska" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLanguageChange("en")}>
              <ListItemText primary="🇺🇸 English" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}

export default Header;
