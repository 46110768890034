import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SectionHeader from "../common/SectionHeader";
import OpeningHours from "../departures/OpeningHours";
import TicketPrices from "../prices/TicketPrices";

function Service() {
  return (
    <Container
      id="features"
      sx={{
        pt: { xs: 8, sm: 10 },
        pb: { xs: 8, sm: 17 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <SectionHeader
        chip={"serviceChip"}
        title={"serviceTitle"}
        text={"serviceText"}
      />
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <OpeningHours showButton={true} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: "100%" }}>
          <TicketPrices showButton={true} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Service;
