import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Stack,
  Typography,
  Card,
  alpha,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/sv";
import "dayjs/locale/en-gb";
import { gray } from "../../App";

function Schedule() {
  const [date, setDate] = useState(dayjs());
  const [schedule, setSchedule] = useState(null);
  const [dayOfWeek, setDayOfWeek] = useState(dayjs().day());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getSchedule();
  }, []);

  const columns = [
    {
      field: "from",
      headerName: `${t("from")}`,
      width: 150,
      editable: false,
    },
    {
      field: "departure",
      headerName: `${t("departure")}`,
      width: 150,
      editable: false,
    },
    {
      field: "arrival",
      headerName: `${t("arrival")}`,
      width: 150,
      editable: false,
    },
  ];

  const getSchedule = () => {
    Axios({
      method: "GET",
      url: "https://zeam-screen-default-rtdb.europe-west1.firebasedatabase.app/schedule2.json",
    })
      .then((res) => {
        setSchedule(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleDate = (date) => {
    setDate(date);
    let day = dayjs(date).day();
    setDayOfWeek(day);
  };

  var rows = [];
  try {
    if (schedule !== null && dayOfWeek === 1) {
      rows = schedule.monday.map((item, index) => {
        return {
          id: index,
          departure: item.departure,
          arrival: item.arrival,
          from:
            item.from === "Norr Mälarstrand"
              ? `${t("kungsholmen")}`
              : `${t("south")}`,
        };
      });
    } else if (schedule !== null && dayOfWeek === 2) {
      rows = schedule.tuesday.map((item, index) => {
        return {
          id: index,
          departure: item.departure,
          arrival: item.arrival,
          from:
            item.from === "Norr Mälarstrand"
              ? `${t("kungsholmen")}`
              : `${t("south")}`,
        };
      });
    } else if (schedule !== null && dayOfWeek === 3) {
      rows = schedule.wednesday.map((item, index) => {
        return {
          id: index,
          departure: item.departure,
          arrival: item.arrival,
          from:
            item.from === "Norr Mälarstrand"
              ? `${t("kungsholmen")}`
              : `${t("south")}`,
        };
      });
    } else if (schedule !== null && dayOfWeek === 4) {
      rows = schedule.thursday.map((item, index) => {
        return {
          id: index,
          departure: item.departure,
          arrival: item.arrival,
          from:
            item.from === "Norr Mälarstrand"
              ? `${t("kungsholmen")}`
              : `${t("south")}`,
        };
      });
    } else if (schedule !== null && dayOfWeek === 5) {
      rows = schedule.friday.map((item, index) => {
        return {
          id: index,
          departure: item.departure,
          arrival: item.arrival,
          from:
            item.from === "Norr Mälarstrand"
              ? `${t("kungsholmen")}`
              : `${t("south")}`,
        };
      });
    } else if (schedule !== null && dayOfWeek === 6) {
      rows = schedule.saturday.map((item, index) => {
        return {
          id: index,
          departure: item.departure,
          arrival: item.arrival,
          from:
            item.from === "Norr Mälarstrand"
              ? `${t("kungsholmen")}`
              : `${t("south")}`,
        };
      });
    } else if (schedule !== null && dayOfWeek === 0) {
      rows = schedule.sunday.map((item, index) => {
        return {
          id: index,
          departure: item.departure,
          arrival: item.arrival,
          from:
            item.from === "Norr Mälarstrand"
              ? `${t("kungsholmen")}`
              : `${t("south")}`,
        };
      });
    }
  } catch (error) {
    console.log(error);
  }

  return (
    <Card
      variant="outlined"
      sx={{
        width: { xs: "80%", sm: "100%" },
        p: { xs: 4, sm: 5 },
        border: `1px solid ${alpha(gray[200], 0.8)}`,
        borderRadius: 10,
        background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
        width={"100%"}
      >
        <Typography
          color="primary.secondary"
          variant="subtitle1"
          fontWeight="bold"
        >
          {t("timetable")}
        </Typography>
        <Typography color="text.secondary" variant="body1" sx={{ mb: 0.5 }}>
          {t("timetableRoute")}
        </Typography>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language === "sv" ? "sv" : "en-gb"}
        >
          <DatePicker value={date} onChange={(date) => handleDate(date)} />
        </LocalizationProvider>
        <Box sx={{ height: isMobile ? 400 : 500, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            //checkboxSelection
            disableSelectionOnClick
            sx={{ mt: 2 }}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ ml: 2 }}
                >
                  {t("timetableNoRows")}
                </Stack>
              ),
            }}
          />
        </Box>
      </Box>
    </Card>
  );
}

export default Schedule;
