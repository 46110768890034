import {
  Box,
  Card,
  Container,
  TextField,
  Typography,
  Button,
  alpha,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconModal from "../IconModal";
import emailjs from "@emailjs/browser";
import { gray } from "../../App";
import SectionHeader from "../common/SectionHeader";
import { motion } from "framer-motion";

function Contact() {
  const { t } = useTranslation();
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_tc0viij",
        "template_t6u1gbi",
        form.current,
        "eDP-oFFxasChK4JzE"
      )
      .then(
        (result) => {
          setIcon("success");
          setTitle(`${t("formResponseTitle")}`);
          setText(`${t("formResponseText")}`);
          setLoading(false);
          handleOpen();
          e.target.reset();
        },
        (error) => {
          setIcon("failed");
          setTitle("Ooops");
          setText(`${t("formErrorText")}`);
          setLoading(false);
          handleOpen();
          console.log(error.text);
        }
      );
  };

  return (
    <Container
      id="contact"
      sx={{
        py: { xs: 10, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <SectionHeader
        chip={"contactChip"}
        title={"contactTitle"}
        text={"contactText"}
      />
      <Card
        variant="outlined"
        sx={{
          width: { xs: "80%", sm: "60%" },
          p: { xs: 4, sm: 3 },
          border: `1px solid ${alpha(gray[200], 0.8)}`,
          borderRadius: 10,
          background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
        }}
      >
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: -10,
            },

            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 1, delay: 0.1 }}
          viewport={{ once: true }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              textAlign: "left",
              justifyContent: "center",
              alignItems: { md: "center" },
            }}
          >
            <Box>
              <Typography
                color="primary.secondary"
                variant="h6"
                fontWeight="bold"
              >
                {t("formTitle")}
              </Typography>
              <form ref={form} onSubmit={sendEmail}>
                <TextField
                  label={t("name")}
                  variant="standard"
                  type="text"
                  name="user_name"
                  required
                  sx={{ width: "100%", marginTop: 2 }}
                />

                <TextField
                  label={t("email")}
                  variant="standard"
                  type="email"
                  name="user_email"
                  required
                  sx={{ width: "100%", marginTop: 2 }}
                />

                <TextField
                  label={t("message")}
                  variant="outlined"
                  name="message"
                  multiline={true}
                  rows={5}
                  required
                  sx={{ width: "100%", marginTop: 5 }}
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: { md: "center" },
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ borderRadius: 10, mt: 5 }}
                  >
                    {t("formButton")}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </motion.div>
      </Card>

      <IconModal
        open={open}
        handleClose={handleClose}
        text={text}
        title={title}
        icon={icon}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default Contact;
