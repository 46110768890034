import React, { useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import SubscribeWidget from "../components/SubscribeWidget";

function Subscribe() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Zeam | Contact Us</title>
        <meta
          name="subscribe"
          content="Subscribe to traffic information."
        />
      </Helmet>
      <Box sx={{ mt: isMobile ? "15vh" : "10vh", minHeight: "85vh"}}>
        <SubscribeWidget />
      </Box>
    </Box>
  );
}

export default Subscribe;