import React, { useEffect } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ZimonSad } from "../assets/zimonSad.svg";

function Missing() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Box
        minHeight={"90vh"}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ZimonSad
          style={{
            width: isMobile ? "50vw" : "30vw",
            height: isMobile ? "20vh" : "30vh",
          }}
        />
        <Typography variant="h5" fontWeight="light">
          Denna sidan kunde inte hittas
        </Typography>
        <Button
          onClick={navigateHome}
          variant="contained"
          sx={{ mt: 5, color: "secondary.main" }}
        >
          Gå till startsidan
        </Button>
      </Box>
    </Box>
  );
}

export default Missing;
