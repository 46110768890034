import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Button,
  Modal,
  useMediaQuery,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Alert,
  Snackbar,
  CircularProgress,
  Container,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ReactComponent as ZimonHappy } from "../../assets/zimon_zimon-happy.svg";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import SectionHeader from "../common/SectionHeader";
import CustomDataGrid from "./CustomDataGrid";

const { origin } = window.location;

const isDev = origin.includes("-dev") || origin.includes("-staging");
const iframeSrc = isDev
  ? "https://card-identification.staging.vayapay.com/register/card-registration/zeam"
  : "https://card-identification.vayapay.com/register/card-registration/zeam";

function User() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cardToken, setCardToken] = useState(null);
  const [travelerAccountReference, setTravelerAccountReference] =
    useState(null);
  const [concessionStatus, setConcessionStatus] = useState("adult");
  const [travelHistory, setTravelHistory] = useState(null);
  const iFrameRef = useRef(null);

  const [activeStep, setActiveStep] = useState(-1);

  const steps = [
    {
      label: `${t("step1Title")}`,
      description: `${t("step1Content")}`,
    },
    {
      label: `${t("step2Title")}`,
      description: `${t("step2Content")}`,
    },
    {
      label: `${t("step3Title")}`,
      description: `${t("step3Content")}`,
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    getInvoice();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    checkConcession(cardToken);
  };

  const handleChange = (event) => {
    setConcessionStatus(event.target.value);
  };

  const initiateSteps = () => {
    window.scrollTo(0, 0);
    getToken();

    const handleMessage = (event) => {
      try {
        if (event.data.event_id === "iframe_response") {
          //console.log(`Event data: ${JSON.stringify(event.data, null, 4)}`);
          var eventData = event.data;
          setCardToken(eventData.data.cards[0].paymentCardReference);
          setTravelerAccountReference(
            eventData.data.cards[0].travelerAccountReference
          );
          setActiveStep(1);
          checkConcession(eventData.data.cards[0].paymentCardReference);
        }
      } catch (error) {
        setAlert(true);
        console.log(error);
      }
    };

    window.addEventListener("message", handleMessage);

    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveStep(2);
  };

  const handleSave = () => {
    setOpen(true);
    getInvoice();
    if (concessionStatus === "discount") {
      setConcession();
    } else {
      removeConcession();
    }
  };

  const getToken = () => {
    const userUrl = window.location.href || "not loaded";
    Axios({
      method: "POST",
      url: `${origin}/token`,
      data: { userUrl: userUrl },
    })
      .then((res) => {
        sendMessage(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        setAlert(true);
      });
  };

  const sendMessage = (token) => {
    try {
      const authToken = token;
      const language = "en";
      iFrameRef.current.contentWindow.postMessage(
        {
          event_id: "load_iframe",
          data: { language: language, token: authToken },
        },
        "*"
      );
      setLoading(false);
    } catch (error) {
      setAlert(true);
      console.log(error);
    }
  };

  const setConcession = () => {
    Axios({
      method: "POST",
      url: `${origin}/concession`,
      data: { cardToken: cardToken, action: "ADD" },
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err.response);
        setAlert(true);
      });
  };

  const removeConcession = () => {
    Axios({
      method: "POST",
      url: `${origin}/concession`,
      data: { cardToken: cardToken, action: "REMOVE" },
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err.response);
        setAlert(true);
      });
  };

  const checkConcession = (cardToken) => {
    Axios({
      method: "POST",
      url: `${origin}/concession`,
      data: { cardToken: cardToken, action: "CHECK" },
    })
      .then((res) => {
        if (res.data.result === true) {
          setConcessionStatus("discount");
        } else {
          setConcessionStatus("adult");
        }
      })
      .catch((err) => {
        console.log(err.response);
        setAlert(true);
      });
  };

  const getInvoice = () => {
    Axios({
      method: "POST",
      url: `${origin}/invoice`,
      // data: { cardToken: cardToken },
      data: { travelerAccountReference: travelerAccountReference },
    })
      .then((res) => {
        setTravelHistory(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        setAlert(true);
      });
  };

  return (
    <>
      <Container
        id="user"
        sx={{
          py: { xs: 10, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: { sx: "left", sm: "center" },
          gap: 3,
        }}
      >
        <SectionHeader
          chip={"userChip"}
          title={"userTitle"}
          text={"userText"}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          //onClick={(() => setActiveStep(0))}
          onClick={initiateSteps}
          sx={{ borderRadius: 10 }}
        >
          {t("userStart")}
        </Button>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          display="flex"
          justifyContent="start"
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {index === 0 && (
                  <Box width={"100%"}>
                    <Typography
                      color="text.secondary"
                      variant="body1"
                      sx={{ mb: 5 }}
                    >
                      {step.description}
                    </Typography>
                    {loading === true ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <div></div>
                    )}
                    <iframe
                      width={"100%"}
                      height="620"
                      id="card-registration-iframe"
                      ref={iFrameRef}
                      src={iframeSrc}
                      allow="fullscreen"
                      title="Vayapay example"
                      style={{ border: "none" }}
                    ></iframe>
                  </Box>
                )}
                {index === 1 && (
                  <Box width={"100%"}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <Typography
                        color="text.secondary"
                        variant="body1"
                        sx={{ mb: 5 }}
                      >
                        {step.description}
                      </Typography>
                      <FormControl>
                        <FormLabel id="radio-buttons-group">
                          {t("step2Title")}
                        </FormLabel>
                        <RadioGroup
                          value={concessionStatus}
                          onChange={handleChange}
                          name="radio-buttons-group"
                          sx={{ mb: isMobile ? 5 : 0 }}
                        >
                          <FormControlLabel
                            value="discount"
                            control={<Radio />}
                            label={t("step2Discount")}
                          />
                          <FormControlLabel
                            value="adult"
                            control={<Radio />}
                            label={t("step2Adult")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("step2Save")}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("step2Skip")}
                      </Button>
                    </div>
                  </Box>
                )}
                {index === 2 && (
                  <Box width={"100%"}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="start"
                      alignItems="start"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        color="text.secondary"
                        variant="body1"
                        sx={{ mb: 3 }}
                      >
                        {step.description}
                      </Typography>
                      <CustomDataGrid travelHistory={travelHistory} />
                    </Box>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1, mb: 5 }}
                      >
                        {t("step3Finish")}
                      </Button>
                      <Button onClick={handleBack} sx={{ mt: 1, mr: 1, mb: 5 }}>
                        {t("step3Back")}
                      </Button>
                    </div>
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Box
            width={"100%"}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="center"
            sx={{ mt: 5 }}
          >
            <Typography>{t("stepFinished")}</Typography>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
              {t("stepFinishedButton")}
            </Button>
          </Box>
        )}
      </Container>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <ZimonHappy style={{ maxWidth: isMobile ? "30vw" : "10vw" }} />
          <Typography sx={{ mt: 0, mb: 4 }}>
            {t("discountModal1")}
            {concessionStatus === "adult"
              ? `${t("discountModal2")}`
              : `${t("discountModal3")}`}
            {t("discountModal4")}
            {concessionStatus === "adult" ? "35 kr" : "20 kr"}.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleClose}
            sx={{
              color: "secondary.main",
            }}
          >
            Ok!
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlert(false);
          }}
          severity="error"
        >
          An error occured. Please refresh the page and try again.
        </Alert>
      </Snackbar>
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  pr: 4,
  pl: 4,
  pb: 4,
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: 3,
};

export default User;
