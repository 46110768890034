import GetMinutesUntilDeparture from "./getMinutesUntilDeparture";
import Axios from "axios";

function getSchedule(setDeparture, side) {
  Axios({
    method: "GET",
    url: "https://zeam-screen-default-rtdb.europe-west1.firebasedatabase.app/schedule2.json",
  })
    .then((res) => {
      var newSchedule = {
        sunday: res.data.sunday,
        monday: res.data.monday,
        tuesday: res.data.tuesday,
        wednesday: res.data.wednesday,
        thursday: res.data.thursday,
        friday: res.data.friday,
        saturday: res.data.saturday,
      };
      //let newDeparture = GetDeparture(newSchedule, "Söder Mälarstrand");
      let newDeparture = GetMinutesUntilDeparture(newSchedule, side);
      if (newDeparture === 0) {
        newDeparture = "<1";
      } else if (newDeparture > 120) {
        newDeparture = "tomorrow";
      } else if (newDeparture > 60) {
        let minutes = newDeparture - 60;
        newDeparture = `1h & ${minutes}`;
      } else {
        
      }
      //newDeparture = newDeparture === 0 ? "<1" : newDeparture;

      setDeparture(newDeparture);
    })
    .catch((err) => {
      console.log(err.response);
    });
}

export default getSchedule;
