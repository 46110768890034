import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";


const { origin } = window.location;

function CustomDataGrid({ travelHistory }) {

  const { t } = useTranslation();
  const columns = [
    { field: "id", headerName: `${t("column1")}`, width: 150 },
    {
      field: "ticket",
      headerName: `${t("column2")}`,
      width: 150,
      editable: false,
    },
    {
      field: "time",
      headerName: `${t("column3")}`,
      width: 150,
      editable: false,
    },
    {
      field: "date",
      headerName: `${t("column4")}`,
      width: 150,
      editable: false,
    },
    {
      field: "price",
      headerName: `${t("column5")}`,
      type: "number",
      width: 110,
      editable: false,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 150,
      editable: false,
    },
    {
      field: "receipt",
      headerName: `${t("column6")}`,
      width: 150,
      editable: false,
    },
  ];

  const getDate = (paymentDateTime) => {
    let date = new Date(paymentDateTime);
    let localeDate = date.toLocaleDateString();
    return localeDate;
  };

  const getTime = (paymentDateTime) => {
    let date = new Date(paymentDateTime);
    let localeTime = date.toLocaleTimeString();
    return localeTime;
  };

  let rows =
    travelHistory !== null
      ? travelHistory.map((item, index) => {
          return {
            id: index,
            ticket:
              item.concessionList === true
                ? `${t("discount")}`
                : `${t("adult")}`,
            time: getTime(item.paymentDateTime),
            date: getDate(item.paymentDateTime),
            price: `${item.paymentAmount} kr`,
            receipt: `${t("download")}`,
            transactionId: item.transactionId,
          };
        })
      : [];

  const getReceipt = (e) => {
    fetch(`${origin}/receipt`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        //filename: `${t("column6")} - ${t("order")} ${e.id}`,
        filename: `${t("column6")}`,
        type: `${t("column2")}:`,
        typeValue: e.row.ticket,
        price: `${t("exVAT")}:`,
        priceValue: e.row.price.slice(0, 2) * 0.94,
        date: `${t("column4")}:`,
        dateValue: `${e.row.date} ${e.row.time} `,
        VAT: `${t("VAT")}:`,
        VATValue: e.row.price.slice(0, 2) * 0.06,
        sum: `${t("sum")}:`,
        sumValue: e.row.price,
        transactionId: e.row.transactionId,
      }),
    }).then(async (res) => {
      if (res.status === 200) {
        const blob = await res.blob();
        const file = new Blob([blob], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      }
    });
  };
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        //checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        onCellClick={(event) => getReceipt(event)}
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              sx={{ ml: 2 }}
            >
              {t("noRows")}
            </Stack>
          ),
        }}
      />
    </Box>
  );
}

export default CustomDataGrid;
