import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import HeroText from "./HeroText";
import { motion } from "framer-motion";

const background = require("../../assets/background2.jpg");

function Hero({ southDeparture, northDeparture }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [videoHeight, setVideoHeight] = useState("56.25vw");

  useEffect(() => {
    const handleResize = () => {
      const height = `${(window.innerWidth * 9) / 16}px`;
      setVideoHeight(height);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <ReactPlayer
            url="https://youtu.be/cRtCYxs7YhQ"
            playing={true}
            loop={true}
            volume={0}
            width="100%"
            height={videoHeight}
            style={{ marginTop: 65 }}
          />
          <Box
            sx={{
              height: "70vh",
              width: "100%",
              color: "white",
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HeroText
              northDeparture={northDeparture}
              southDeparture={southDeparture}
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100%",
              zIndex: 0,
            }}
          >
            <ReactPlayer
              url="https://youtu.be/cRtCYxs7YhQ"
              playing={true}
              loop={true}
              volume={0}
              width="100%"
              height={videoHeight}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "200vh",
              width: "100%",
            }}
          >
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                width: "100%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
                color: "white",
              }}
            >
              <motion.div
                variants={{
                  hidden: {
                    opacity: 0,
                    y: -20,
                  },

                  visible: {
                    opacity: 1,
                    y: 0,
                  },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.1 }}
                viewport={{ once: true }}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <HeroText
                  northDeparture={northDeparture}
                  southDeparture={southDeparture}
                />
              </motion.div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default Hero;
