import { Box, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowUpIcon from "remixicon-react/ArrowUpLineIcon"

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Box sx={{position: "fixed", bottom: "25px", right: "25px", zIndex: 999}}>
      {isVisible && (
        <Fab color="primary" onClick={scrollToTop}>
            <ArrowUpIcon />
        </Fab>
      )}
    </Box>
  );
}
