import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { motion } from "framer-motion";

function SectionHeader({ chip, title, text }) {
  const { t } = useTranslation();
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          y: -20,
        },

        visible: {
          opacity: 1,
          y: 0,
        },
      }}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 1, delay: 0.1 }}
      viewport={{ once: true }}
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Chip
          label={t(chip)}
          color="primary"
          sx={{ mb: 3, p: 1, fontSize: 14, fontWeight: "bold" }}
        />
        <Typography component="h2" variant="h4" color="text.primary">
          {t(title)}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t(text)}
        </Typography>
      </Box>
    </motion.div>
  );
}

export default SectionHeader;
