import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  alpha,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gray } from "../../App";
import { motion } from "framer-motion";

function OpeningHours({ showButton }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const navigateDepartures = () => {
    navigate("/departures");
  };
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          y: -10,
        },
        visible: {
          opacity: 1,
          y: 0,
        },
      }}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <Card
        variant="outlined"
        sx={{
          width: { xs: "80%", sm: "90%" },
          p: { xs: 4, sm: 3 },
          border: `1px solid ${alpha(gray[200], 0.8)}`,
          borderRadius: 10,
          background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            textAlign: "left",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { md: "center" },
            gap: 2.5,
          }}
        >
          <Box sx={{ px: { sx: 0, sm: 2 } }}>
            <Typography
              color="primary.secondary"
              variant="subtitle1"
              fontWeight="bold"
            >
              {t("openingHoursTitle")}
            </Typography>
            <Typography color="text.secondary" variant="body1" sx={{ my: 0.5 }}>
              {t("chargingHours")}
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemText primary={t("hours1")} secondary="07:40-22:00" />
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemText primary={t("hours2")} secondary="07:40-23:30" />
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemText primary={t("hours3")} secondary="09:00-23:30" />
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemText primary={t("hours4")} secondary="09:00-22:00" />
              </ListItem>
            </List>
          </Box>
        </Box>
        {showButton && (
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={navigateDepartures}
              sx={{ borderRadius: 10 }}
            >
              {t("timetable")}
            </Button>
          </CardActions>
        )}
      </Card>
    </motion.div>
  );
}

export default OpeningHours;

