import {
  Box,
  Typography,
  Button,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { ReactComponent as ZimonSad } from "../assets/zimonSad.svg";
import { ReactComponent as ZimonHappy } from "../assets/zimon_zimon-happy.svg";

function IconModal({ open, handleClose, title, text, icon }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {icon === "success" ? (
          <ZimonHappy style={{ maxWidth: isMobile ? "30vw" : "10vw" }} />
        ) : (
          <ZimonSad style={{ maxWidth: isMobile ? "30vw" : "10vw" }} />
        )}
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{text}</Typography>

        <Button variant="contained" onClick={handleClose} sx={{ mt: 3, color: "secondary.main" }}>
          Ok!
        </Button>
      </Box>
    </Modal>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "80vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  pt: 0,
  borderRadius: 3,
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  flexDirection: "column",
};

export default IconModal;
