import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "sv",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
    en: {
        translation: {
          intro1: "We'll take you across Riddarfjärden!",
          intro2: "Experience the world's first electric and autonomous passenger ferry. We travel across Riddarfjärden between Kungsholmen and Södermalm. Departures every 20 minutes from each side, almost all day, every day.",
          intro3: "Next departure:",
          serviceChip: "Service",
          serviceTitle: "Zeam - The world's first autonomous passenger ferry",
          serviceText: "Zeam travels between Kungsholmen and Södermalm in just six minutes. Zeam connects the districts and transports people quickly, comfortably, and in an eco-friendly manner. Instead of seeing the water as a barrier, Zeam enables shortcuts, creating more sustainable urban mobility. The Norwegian shipping company Torghatten stands behind Zeam.",
          nextDeparture: "Next departure",
          welcome: "We look forward to welcoming you on board!",
          valueTitle: "Why should you travel with Zeam?",
          valueText: "The benefits of traveling with Zeam are many, but the main ones are that you travel green, fast, and comfortably.",
          greenTitle: "Travel Green",
          greenText: "Zeam's ferry is electric and thus completely emission-free. Moreover, it is largely powered by solar panels on the roof, the rest of the electricity is green.",
          timeTitle: "Travel Fast",
          timeText: "By traveling over the water instead of around, you save time and can do other things. In just six minutes, Zeam takes you across Riddarfjärden.",
          relaxTitle: "Travel Comfortably",
          relaxText: "The electric ferry allows you to travel silently across Riddarfjärden. You avoid noise, congestion, and also get fresh air in the process.",
          mapChip: "Find",
          mapTitle: "Find us",
          mapText: "Zeam operates between Kungsholmen and Södermalm, with a stop on each side. The stop at Kungsholmen is outside Orangeriet. The stop at Södermalm is below Skinnarviksberget.",
          northTitle: "Kungsholmen",
          southTitle: "Södermalm",
          directions: "Find us",
          crossing: "Crossing",
          length: "Length: 650 meters",
          time: "Time: about 6 minutes",
          ferryTitle: "A smarter ferry",
          ferryText: "Zeam is not only fast and smooth, but it is also smart and controlled by a computer. The computer ensures that the ferry has perfect vision day and night and can understand its surroundings, therefore it safely helps you across every time. There is always an operator on board the ferry who constantly monitors the system and is ready to take control for extra safety. The vision is that in the future, the operator can be located on land and monitor one or more ferries at the same time, without compromising on safety.",
          ferryButton: "How it works",
          aboutChip: "About Zeam",
          aboutTitle: "About us",
          aboutText: "Zeam stands for Zero Emission Autonomous Mobility. The brand was created in 2023 under the Norwegian shipping company Torghatten which has been around for over 150 years. The development was carried out in collaboration with Zeabuz, which is a world leader in autonomous technology for the maritime sector.",
          missionTitle: "Making Urban Life Flow",
          missionText: "The goal of Zeam is to improve the flow of urban life. We aim to create an environmentally and economically sustainable mobility concept on water in urban areas. With all-electric propulsion combined with solar panels, we offer an emission-free service that minimally burdens the electrical grid. Unlike a bridge, Zeam provides a flexible solution and by reclaiming the use of waterways, enhances mobility in cities. This contributes to making urban environments more sustainable, connected, and accessible to everyone.",
          backgroundTitle: "Background",
          backgroundText: "Although Zeam is a new product, there is extensive research behind the concept. The idea of autonomous urban ferries was initiated at the Norwegian University of Science and Technology, NTNU, in Trondheim. The concept was developed by experienced researchers who in 2017 created the first prototype called MilliAmpere. MilliAmpere was a small ferry that operated a crossing of 100 meters in central Trondheim and extensive testing of the technology was conducted. This led to the founding of the company Zeabuz in 2019 to further develop and commercialize the technology. A larger prototype was developed, named MilliAmpere 2, and was completed in 2021. MilliAmpere 2 was put into trial operation open to the public for four weeks during 2022, with a total of 1,500 passengers traveling during the period without any intervention from a commander. Meanwhile, the Norwegian shipping company Torghatten became a shareholder of Zeabuz and joined the project as an operator. Zeam was formed as a brand and is created to represent a new mobility concept with small and autonomous electric urban ferries. Torghatten decided to create a new ferry route in Stockholm over Riddarfjärden, and thereafter the first ferry, which has been in operation since June 8, 2023, and is named MF Estelle. MF Estelle is specifically adapted to the conditions prevailing over Riddarfjärden.",
          contactChip: "Contact",
          contactTitle: "Contact us",
          contactText: "Do you have any questions or concerns? Get in touch with us through the contact form, and we will help you.",
          newsTitle: "News",
          newsText: "Here you can read the latest news about Zeam (opens in a new tab).",
          newsButton: "News",
          subscribeTitle: "Get traffic information",
          subscribeText: "Receive traffic information and news directly to your email or mobile by filling in your contact details.",
          subscribeButton: "Subscribe",
          internationalButton: "International news",
          formTitle: "Contact Form",
          name: "Name",
          email: "Email",
          message: "Message",
          formButton: "Send",
          formResponseTitle: "Thank you for your message",
          formResponseText: "We will get back to you in the coming days.",
          formErrorText: "The message could not be sent. Please try again.",
          priceChip: "Tickets",
          priceTitle: "Our tickets",
          priceTitle1: "Receipt",
          priceTitle2: "Discount",
          priceText: "The easiest way to buy tickets is here on our website. After your purchase, you will receive a receipt via email that also serves as a ticket on board. You can also buy a ticket with your credit card (Visa or Mastercard) at one of our payment terminals located at the boat stops. Once you tap your card against the terminal, the ticket is stored on the card. The ticket is valid for 60 minutes.",
          priceText1: "If you have purchased a ticket on our website, you will receive your receipt sent to your email address. If you have purchased a ticket at one of our payment terminals, you can go to 'My Trips' to get an overview of these payments and print receipts.",
          priceText2: "If you are a student, senior, or youth, you can travel with us for 20 SEK. To get the discount at our payment terminals, you can register your payment card on 'My Trips' to receive a discounted price on your future ticket purchases at the terminals. When you then use this payment card at our payment terminals, you will automatically receive the discounted price.",
          ticketButton: "Buy tickets",
          checkoutStep1: "Select tickets",
          checkoutDescription1: "Choose how many tickets you want to purchase. Each ticket lasts for 60 minutes from the time of purchase. We recommend that you wait to purchase tickets until you are at the boat stop.",
          checkoutDescription12: "Children under 15 travel for free with an adult. Bicycles and strollers can be brought along at no extra cost.",
          checkoutDescription13: "Our period ticket lasts for 30 days, and with it, you can travel with us as much as you want during the entire period. When you make the purchase, a receipt will be sent to your email. The receipt also functions as the ticket. The last valid date for the ticket is stated on the receipt.",
          checkoutTicket1: "Adult (35 SEK)",
          checkoutTicket2: "Youth 15-25 years (20 SEK)",
          checkoutTicket3: "Student (20 SEK)",
          checkoutTicket4: "Senior 65+ (20 SEK)",
          checkoutPeriodTicket1: "30 days Adult (900 SEK)",
          checkoutPeriodTicket2: "30 days Student, Senior, Youth (590 SEK)",
          checkoutButton: "Pay",
          checkoutButtonPeriod: "Buy period ticket",
          checkoutButtonSingle: "Buy single ticket",
          checkoutStep2: "Pay",
          checkoutDescription2: "Pay using Swish, bank card, Google Pay, or Apple Pay through our payment provider Stripe. A receipt for your purchase will be sent to the email address you provide. The receipt also serves as a ticket.",
          checkoutStep2Button: "Back",
          checkoutStep3: "Purchase Confirmation",
          checkoutDescription3: "Your purchase has been completed! A receipt will be sent to your email address. The receipt also serves as a ticket on board. If you wish to cancel your purchase, please contact info@zeam.se and we will assist you. Welcome aboard!",
          priceButton: "To My trips",
          ticketTitle: "Prices",
          ticketAdult: "Adult",
          ticketStudent: "Student (valid student ID required)",
          ticketYouth: "Youth (15-25)",
          ticketSenior: "Senior (65+)",
          ticketChild: "Child (under 15 accompanied by an adult)",
          priceChild: "Free",
          userChip: "Trips",
          userTitle: "My trips",
          userText: "Here you can get an overview of your trips with us, download receipts, and register your credit card for a discount if you are a youth, student, or senior. You do not need to have an account with us, all you need is your Mastercard or Visa card.",
          userStart: "Get started",
          step1Title: "Register credit card",
          step1Content: "In order to give you a discount on future purchases or to show your travel history, we need information about your credit card that you use with us. Your card details are safely processed by our payment provider Vayapay.",
          step2Title: "Get Discount",
          step2Content: "If you are a youth (16-25), student, or senior (65+), you get a discounted price on your ticket. You then only pay 20 kr. Mark the applicable option below, and you will get the correct price the next time you pay with your registered card at our payment terminals.",
          step2Save: "Save",
          step2Skip: "Skip",
          step2Discount: "Discount 20 kr (senior, student, youth)",
          step2Adult: "Adult 35 kr",
          step3Title: "View travel history",
          step3Content: "Here you can see your travel history with us, as well as print receipts. You will find all the trips that have been paid with the card you entered in step 1. Click on a row to download receipts for that trip.",
          step3Finish: "Done",
          step3Back: "Back",
          stepFinished: "Thank you for traveling with us at Zeam!",
          stepFinishedButton: "Back",
          discountModal1: "Your card is now registered",
          discountModal2: "as an adult.",
          discountModal3: "for a reduced price.",
          discountModal4: "When you tap with us in the future, you will be charged",
          column1: "Index",
          column2: "Ticket",
          column3: "Time",
          column4: "Date",
          column5: "Price",
          column6: "Receipt",
          adult: "Adult",
          discount: "Discount",
          download: "Download",
          order: "Order number",
          exVAT: "Price excl. VAT:",
          VAT: "VAT 6%",
          sum: "Total",
          noRows: "We could not find any purchases with the specified credit card",
          departureChip: "Timetable",
          departureTitle: "Our departures",
          departureText: "MF Estelle departs three times an hour (every 20 minutes) from each side during opening hours. The ferry has two breaks each day (11:00-12:00 and 18:00-19:00) when we do not operate.",
          departureText2: "Departures always occur on the minutes",
          openingHoursTitle: "Opening hours",
          chargingHours: "Here are our opening hours. Please note that we currently have charging breaks from 11:00-12:00 and 18:00-19:00 every day when we do not operate.",
          hours1: "Monday-Thursday",
          hours2: "Friday",
          hours3: "Saturday",
          hours4: "Sunday",
          departures: "Departures",
          kungsholmen: "Norr Mälarstrand",
          south: "Söder Mälarstrand",
          cookies: "This website uses cookies to enhance user experience.",
          cookieButton: "Learn more",
          menuHome: "Home",
          menuDepartures: "Timetable",
          menuTickets: "Tickets",
          menuFind: "Find us",
          menuAbout: "About us",
          menuContact: "Contact",
          menuNews: "News",
          menuInternationalNews: "International News",
          menuUser: "My trips",
          menuLanguage: "English",
          menuService: "The Service",
          menuCompany: "The Company",
          menuCareers: "Careers",
          menuLegal: "Legal",
          from: "From",
          arrival: "Arrival",
          departure: "Departure",
          timetable: "Timetable",
          timetableRoute: "Route: Riddarfjärden (1)",
          timetableNoRows: "Something went wrong. We could not find any times.",
          cancelledDeparture: "cancelled due to ice",
          noDepartures: "All departures are temporarily suspended due to ice on Riddarfjärden.",
          surveyText: "We would like to know your opinion about self-driving ferries. We ask that you take a minute of your time to fill out our survey. It is completely anonymous.",
          surveyButton: "Survey about self-driving ferries",
          faqChip: "FAQ",
          faqTitle: "Questions and Answers",
          faqText: "Here you can find common questions and answers. Do not hesitate to contact us if you cannot find the information you are looking for.",
          q1: "How do I buy a ticket?",
          a1: "You can buy tickets here on our webpage or through our payment terminals at the boat stops. You tap your credit card against the payment machine, and then the ticket is on your card. If you buy online you will instead receive your ticket on your email. The ticket is valid for 60 minutes. Press the button to purchase online.",
          q2: "Can I buy monthly passes or group tickets?",
          a2: "Yes, you can buy monthly passes and several single tickets for your group online. Currently, only single tickets can be bought in our payment terminals on the boat stops.",
          q3: "I am a student or senior. How do I get a discount on my ticket purchases?",
          a3: "You can choose discounted tickets when you buy online. To get a discount when you buy a ticket in our payment terminals, you first need to have registered your credit card for a discount here on our website. Next time you pay with the same card in one of our payment terminals, you will automatically get the discounted price. Press the button below to get started.",
          q4: "How do I know if the ferry is operating as scheduled?",
          a4: "If for any reason, such as high winds or maintenance, we are unable to operate the ferry according to schedule, we will announce this on the website and at our boat stops. You can also subscribe to traffic updates from us and receive a message directly on your mobile.",
          q5: "Can I bring my bicycle/dog/stroller on the ferry?",
          a5: "Yes! You can bring your bicycle, dog, and stroller on the ferry at no extra cost."
        },
      },
      sv: {
        translation: {
          intro1: "Vi tar dig över Riddarfjärden!",
          intro2: "Upplev världens första elektriska och självkörande passagerarfärja. Vi åker över Riddarfjärden mellan Kungsholmen och Södermalm. Avgång var 20:e minut från varje sida, nästan hela dagen, varje dag.",
          intro3: "Nästa avgång:",
          serviceChip: "Tjänst",
          serviceTitle: "Zeam - Världens första självkörande passagerarefärja",
          serviceText: "Zeam åker mellan Kungsholmen och Södermalm på endast sex minuter. Zeam syr ihop stadsdelarna och transporterar människor snabbt, bekvämt och klimatsmart. I stället för att se vattnet som en barriär möjliggör Zeam genvägar, vilket skapar en mer hållbar urban mobilitet. Bakom Zeam står det norska rederiet Torghatten.",
          nextDeparture: "Nästa avgång",
          welcome: "Vi ser fram emot att välkomna dig ombord!",
          valueTitle: "Varför ska du åka med Zeam?",
          valueText: "Fördelarna att resa med Zeam är många, men de främsta är att du reser grönt, snabbt och bekvämt.",
          greenTitle: "Res Grönt",
          greenText: "Zeams färja är elektrisk och därmed helt utsläppsfri. Dessutom laddas den till stor del av solceller på taket, resten av elen är grön.",
          timeTitle: "Res snabbt",
          timeText: "Genom att åka över vattnet i stället för runt, sparar du tid och kan göra annat. På endast sex minuter tar Zeam dig över Riddarfjärden.",
          relaxTitle: "Res bekvämt",
          relaxText: "Den elektriska färjan gör att du åker ljudlöst över Riddarfjärden. Du slipper buller, trängsel och får dessutom frisk luft på köpet.",
          mapChip: "Hitta",
          mapTitle: "Hitta hit",
          mapText: "Zeam går mellan Kungsholmen och Södermalm, med en hållplats på varje sida. Hållplatsen på Kungsholmen är utanför Orangeriet. Hållplatsen på Södermalm är nedanför Skinnarviksberget.",
          northTitle: "Kungsholmen",
          southTitle: "Södermalm",
          directions: "Hitta hit",
          crossing: "Övergång",
          length: "Längd: 650 meter",
          time: "Tid: ca 6 minuter",
          ferryTitle: "En smartare färja",
          ferryText: "Zeam är inte bara snabb och smidig, den är även smart och styrs med hjälp av en dator. Datorn gör så att färjan har perfekt syn dag som natt och kan förstå sin omgivning, därför hjälper den dig tryggt över varje gång. Det finns alltid en operatör ombord färjan som hela tiden övervakar systemet och är beredd att ta över kontrollen för extra säkerhet. Visionen är att operatören i framtiden kan befinna sig på land och övervaka en eller flera färjor samtidigt, utan att tumma på säkerheten.",
          ferryButton: "Hur det fungerar",
          aboutChip: "Om Zeam",
          aboutTitle: "Om oss",
          aboutText: "Zeam står för Zero Emission Autonomous Mobility. Varumärket skapades 2023 under det norska rederiet Torghatten som har funnits i över 150 år. Utvecklingen har skett i samarbete med Zeabuz som är världsledande inom självkörande teknik till den maritima sektorn.",
          missionTitle: "Making Urban Life Flow",
          missionText: "Målet med Zeam är att få ett bättre flyt för stadslivet. Vi vill skapa ett miljömässigt och ekonomiskt hållbart mobilitetskoncept på vatten i urbana miljöer. Med helelektrisk framdrift i kombination med solceller får vi en utsläppsfri tjänst som belastar elnätet i minsta möjliga grad. Till skillnad från en bro, skapar Zeam just en flexibel lösning och genom att återta användningen av vattenvägar stärks rörligheten i städerna. Detta bidrar till att stadsmiljöer bli mer hållbara, uppkopplade och tillgängliga för alla.",
          backgroundTitle: "Bakgrund",
          backgroundText: "Även om Zeam är en ny produkt, så ligger det mycket forskning bakom konceptet. Idéen om autonoma stadsfärjor startades vid Norges Teknisk-Naturvetenskapliga Univesitet, NTNU, i Trondheim. Konceptet blev utvecklat av erfarna forskare som år 2017 skapade den första prototypen som hette MilliAmpere. MilliAmpere var en liten färja som trafikerade en övergång på 100 meter i centrala Trondheim och mycket testning av tekniken gjordes. Detta ledde till att företaget Zeabuz grundades år 2019 för att vidareutveckla och kommersialisera tekniken. En större prototyp utvecklades, som fick heta MilliAmpere 2 och blev färdig 2021. MilliAmpere 2 sattes i testdrift som var öppen för allmänheten i fyra veckor under 2022, totalt reste 1 500 passagerare under perioden utan något ingripande från en befälhavare. Samtidigt blev det norska rederiet Torghatten delägare av Zeabuz och gick med i projektet som operatör. Zeam bildades som varumärke och är skapat för att representera ett nytt mobilitetskoncept med små och autonoma elektriska stadsfärjor. Torghatten bestämde sig för att skapa en ny färjerutt i Stockholm över Riddarfjärden och därefter utformades den första färjan, som är i drift sedan den 8 juni 2023 och heter MF Estelle. MF Estelle är särskilt anpassad för de förhållanden som råder över Riddarfjärden.",
          contactChip: "Kontakt",
          contactTitle: "Kontakta oss",
          contactText: "Har du några frågor eller funderingar? Hör av dig till oss genom kontaktformuläret så hjälper vi dig.",
          newsTitle: "Nyheter",
          newsText: "Här kan du läsa de senaste nyheterna om Zeam (öppnas i ny flik).",
          newsButton: "Nyheter",
          subscribeTitle: "Få trafikinformation",
          subscribeText: "Få trafikinformation och nyheter direkt till din mail eller mobil genom att fylla i dina kontaktuppgifter.",
          subscribeButton: "Prenumerera",
          internationalButton: "Internationella nyheter",
          formTitle: "Kontaktformulär",
          name: "Namn",
          email: "Email",
          message: "Meddelande",
          formButton: "Skicka",
          formResponseTitle: "Tack för ditt meddelande",
          formResponseText: "Vi hör av oss till dig de kommande dagarna.",
          formErrorText: "Meddelandet kunde inte skickas. Försök igen.", 
          priceChip: "Biljetter",
          priceTitle: "Våra biljetter",
          priceTitle1: "Kvitto",
          priceTitle2: "Rabatt",
          priceText: "Biljett köper du enklast här på vår hemsida. När du har genomfört köpet får du ett kvitto på din email som också fungerar som biljett ombord. Du kan också köpa biljett med ditt betalkort (Visa eller Mastcard) på en av våra betalterminaler som finns på båthållsplatserna. När du har blippat ditt kort mot terminalen så finns biljetten på kortet. Biljetten är giltig i 60 minuter.",
          priceText1: "Om du har köpt biljett på vår hemsida får du ditt kvitto skickat till din mailadress. Om du har köpt biljett på en av våra betalterminal kan du gå in på Mina resor för att få en översikt på dessa betalningar och skriva ut kvitton.",
          priceText2: "Om du är student, senior eller ungdom får du resa för 20 kr med oss. För att få rabatten på våra betalterminaler kan du registrera ditt betalkort på Mina resor för att få rabatterat pris på dina framtida biljettköp på terminalerna. När du sedan använder detta betalkort på våra betalterminaler kommer du automatiskt få det rabatterade priset.",
          ticketButton: "Köp biljetter",
          checkoutStep1: "Välj biljetter",
          checkoutDescription1: "Välj hur många biljetter du vill köpa. Varje biljett varar i 60 minuter från och med att köpet genomförs. Vi rekommenderar att du väntar med att köpa biljett tills du är på båthållplatsen.",
          checkoutDescription12: "Barn under 15 år åker gratis i vuxet sällskap. Cykel och barnvagn få tas med utan extra kostnad.",
          checkoutDescription13: "Vår periodbiljett varar i 30 dagar och med den kan du åka med oss hur mycket du vill under hela perioden. När du genomför köpet kommer ett kvitto skickas till din mail. Kvittot fungerar även som biljett. Sista giltiga datum för biljetten står på kvittot.",
          checkoutTicket1: "Vuxen (35 kr)",
          checkoutTicket2: "Ungdom 15-25 år (20 kr)",
          checkoutTicket3: "Student (20 kr)",
          checkoutTicket4: "Senior 65+ (20 kr)",
          checkoutPeriodTicket1: "30 dagar Vuxen (900kr)",
          checkoutPeriodTicket2: "30 dagar Student, Senior, Ungdom (590kr)",
          checkoutButton: "Betala",
          checkoutButtonPeriod: "Köp periodbiljett",
          checkoutButtonSingle: "Köp singelbiljett",
          checkoutStep2: "Betala",
          checkoutDescription2: "Betala med Swish, bankkort, Google Pay eller Apple Pay genom vår betalningsleverantör Stripe. Kvitto för ditt köp skickas till mailadressen som du anger. Kvittot fungerar även som biljett.",
          checkoutStep2Button: "Tillbaka",
          checkoutStep3: "Köpbekräftelse",
          checkoutDescription3: "Ditt köp har genomförts! Ett kvitto kommer skickas till din mailadress. Kvittot fungerar även som biljett ombord. Om du ångrar ditt köp ska du höra av dig till info@zeam.se så hjälper vi dig. Välkommen ombord!",
          priceButton: "Till Mina resor",
          ticketTitle: "Priser",
          ticketAdult: "Vuxen",
          ticketStudent: "Student (krävs giltigt student-id)",
          ticketYouth: "Ungdom (15-25)",
          ticketSenior: "Senior (65+)",
          ticketChild: "Barn (under 15 år i vuxet sällskap)",
          priceChild: "Gratis",
          userChip: "Resor",
          userTitle: "Mina resor",
          userText: "Här kan du få en översikt på dina resor med oss, ladda ned kvitton samt registrera ditt betalkort för rabatt om du är ungdom, student eller senior. Du behöver inte ha ett konto hos oss, allt du behöver är ditt Mastercard- eller Visa-kort.",
          userStart: "Sätt igång",
          step1Title: "Registrera betalkort",
          step1Content: "För att kunna ge dig rabatt på framtida köp eller visa din resehistorik behöver vi information om ditt betalkort som du använder hos oss. Dina kortuppgifter behandlas tryggt av vår betalningsleverantör Vayapay.",
          step2Title: "Få Rabatt",
          step2Content: "Är du ungdom (16-25), student eller senior (65+) får du rabatterat pris på din biljett. Du betalar då endast 20 kr. Markera det som gäller för dig nedan så kommer du att få rätt pris bästa gång du betalar med ditt registrerade kort på våra betalterminaler.",
          step2Save: "Spara",
          step2Skip: "Hoppa över",
          step2Discount: "Rabatt 20 kr (senior, student, ungdom)",
          step2Adult: "Vuxen 35 kr",
          step3Title: "Se resehistorik",
          step3Content: "Här kan du se din resehistorik med oss, samt skriva ut kvitton. Du hittar alla resor som har betalts med kortet som du skrev in i steg 1. Klicka på en rad för att ladda ner kvitton för den resan.",
          step3Finish: "Färdig",
          step3Back: "Tillbaka",
          stepFinished: "Tack för att du reser med oss på Zeam!",
          stepFinishedButton: "Tillbaka",
          discountModal1: "Ditt kort är nu registrerat ",
          discountModal2: "som vuxen. ",
          discountModal3: "för nedsatt pris. ",
          discountModal4: "När du blippar hos oss framöver kommer du debiteras ",
          column1: "Index",
          column2: "Biljett",
          column3: "Tid",
          column4: "Datum",
          column5: "Pris",
          column6: "Kvitto",
          adult: "Vuxen",
          discount: "Rabatt", 
          download: "Ladda ner",
          order: "Ordernummer",
          exVAT: "Pris exkl moms:",
          VAT: "Moms 6%",
          sum: "Summa",
          noRows: "Vi hittade inga köp hos oss med angivet betalkort",
          departureChip: "Tidtabell",
          departureTitle: "Våra avgångar",
          departureText: "MF Estelle avgår tre gånger i timmen (var 20:e minut) från varje sida under öppettiderna. Färjan har två pauser varje dag (11:00-12:00 och 18:00-19:00) då vi inte kör.",
          departureText2: "Avgångarna sker alltid på minuterna",
          openingHoursTitle: "Öppettider",
          chargingHours: "Här är våra öppettider. Observa att vi för tillfället har laddpauser 11:00-12:00 och 18:00-19:00 varje dag då vi inte kör.",
          hours1: "Måndag-Torsdag",
          hours2: "Fredag",
          hours3: "Lördag",
          hours4: "Söndag",
          departures: "Avgångar",
          kungsholmen: "Norr Mälarstrand",
          south: "Söder Mälarstrand",
          cookies: "Denna hemsida använder kakor för att förbättra användarupplevelsen. ",
          cookieButton: "Läs mer",
          menuHome: "Hem",
          menuDepartures: "Tidtabell",
          menuTickets: "Biljetter",
          menuFind: "Hitta hit",
          menuAbout: "Om oss",
          menuContact: "Kontakt",
          menuNews: "Nyheter",
          menuInternationalNews: "Internationella Nyheter",
          menuUser: "Mina resor",
          menuLanguage: "Svenska",
          menuService: "Tjänsten",
          menuCompany: "Företaget",
          menuCareers: "Karriär",
          menuLegal: "Rättsligt",
          from: "Från",
          arrival: "Ankomst",
          departure: "Avgång",
          timetable: "Tidtabell",
          timetableRoute: "Rutt: Riddarfjärden (1)",
          timetableNoRows: "Något gick fel. Vi kunde inte hitta några tider.",
          cancelledDeparture: "inställd p.g.a is",
          noDepartures: "Alla avgångar är tyvärr tillfälligt inställda p.g.a is på Riddarfjärden.",
          surveyText: "Vi skulle vilja veta din åsikt om självkörande färjor. Vi ber dig att ta en minut av din tid att fylla i vår enkät. Det är helt anonymt.",
          surveyButton: "Enkät om självkörande färjor",
          faqChip: "FAQ",
          faqTitle: "Frågor och Svar",
          faqText: "Här hittar du vanliga frågor och svar. Tveka inte att höra av dig till oss om du inte hittar informationen du söker.",
          q1: "Hur köper jag biljett?",
          a1: "Biljett köper du antingen här på vår hemsida eller genom våra betalterminaler på båthållplatserna. Du blippar ditt betalkort mot betalautomaten så finns sedan biljetten på ditt kort. Om du köper biljett online skickas denna till din mail. Biljetten är giltig i 60 minuter. Tryck på knappen för att köpa biljetter online.",
          q2: "Kan jag köpa månadskort eller gruppbiljetter?",
          a2: "Ja, du kan köpa både månadskort och flera singelbiljetter till hela ditt sällskap på vår hemsida. För tillfället går det endast att köpa enkelbiljetter i våra betalautomater.",
          q3: "Jag är student eller senior. Hur gör jag för att få rabatt på mina biljettköp?",
          a3: "När du köper biljett online kan du välja den biljettyp som gäller för dig. För att få rabatt när du köper biljett i våra betalterminaler behöver du först ha registrerat ditt betalkort för rabatt här på vår hemsida. Nästa gång du betalar med samma kort i någon av våra betalterminaler kommer du automatiskt få rabatterat pris. Tryck på knappen nedan för att komma igång.",
          q4: "Hur vet jag om färjan går som den ska?",
          a4: "Om vi av någon anledning, till exempel på grund av hög vind eller underhåll, inte kan köra färjan enligt schemat så kommer vi meddela detta på hemsidan och på våra båthållplatser. Du kan också prenumera på trafikuppdateringar från oss och få ett meddelande direkt i mobilen.",
          q5: "Får jag ta med min cykel/hund/barnvagn på färjan?",
          a5: "Ja! Du får ta med både din cykel, hund och barnvagn på färjan utan extra kostnad.",
        },
      },
    },
  });

export default i18n;