import Axios from "axios";

function getStoppage (setStoppage) {
    Axios({
      method: "GET",
      url: "https://zeam-screen-default-rtdb.europe-west1.firebasedatabase.app/stoppage.json",
    })
      .then((res) => {
        setStoppage(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  export default getStoppage