import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Contact from "../components/contact";

function ContactScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Zeam | Contact Us</title>
        <meta
          name="description"
          content="Do you have any questions or concerns? Get in touch with us through the contact form and we will assist you."
        />
      </Helmet>
      <Contact />
    </>
  );
}

export default ContactScreen;
