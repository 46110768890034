import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Snackbar,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import CustomTabPanel from "../CustomTabPanel";
import Axios from "axios";

const stripePromise = loadStripe(
  //"pk_test_51LmFDuIfGTG7lfEnCPLBf1ntR5WEchYQJGa0Koc3NMASfzNchTDQ1BV6dVONi77fv0t9W6rMyWqXto8zEqHqYNE1007NzusGEG"
  "pk_live_51LmFDuIfGTG7lfEnkkFFYxmFd7rhqUlkhNhTBzh2IyEpKUxsYygfsOCETh55dU1bQxlVbuMLIGCNx2CXzUrV9mBp00GwtUjJQw"
);
const { origin } = window.location;

function PaymentTab({ value }) {
  const [alert, setAlert] = useState({
    text: "",
    success: false,
    show: false,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [tickets, setTickets] = useState({
    adult: 0,
    discount: 0,
    period: 0,
  });
  const [stripeOptions, setStripeOptions] = useState(null);
  const prices = { adult: 35, discount: 20 };

  const steps = [
    {
      label: "Välj biljetter",
      description:
        "Välj hur många biljetter du vill köpa. Varje biljett varar i 60 minuter från och med att köpet genomförs. Vi rekommenderar att du väntar med att köpa biljett tills du är på båthållplatsen.",
    },
    {
      label: "Betala",
      description:
        "Betala med ditt bankkort, Google Pay eller Apple Pay genom vår betalningsleverantör Stripe. Kvitto för ditt köp skickas till mailadressen som du anger. Kvittot fungerar även som biljett.",
    },
  ];

  const incrementTicket = (type) => {
    setTickets((prevTickets) => ({
      ...prevTickets,
      [type]: prevTickets[type] + 1,
    }));
  };

  // Function to decrement the ticket count
  const decrementTicket = (type) => {
    setTickets((prevTickets) => ({
      ...prevTickets,
      [type]: prevTickets[type] > 0 ? prevTickets[type] - 1 : 0,
    }));
  };

  const totalPrice =
    tickets.adult * prices.adult + tickets.discount * prices.discount;

  const getTickets = () => {
    setActiveStep(1);
    const fetchClientSecret = async () => {
      return Axios({
        method: "POST",
        url: `${origin}/create-checkout-session`,
        data: tickets,
      })
        .then((res) => res.data.clientSecret)
    };

    const options = { fetchClientSecret };
    setStripeOptions(options);
  };

  return (
    <div id="checkout">
      <CustomTabPanel value={value} index={1}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          display="flex"
          justifyContent="start"
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {index === 0 && (
                  <Box>
                    <Typography
                      color="text.secondary"
                      variant="body1"
                      sx={{ mb: 5 }}
                    >
                      {step.description}
                    </Typography>
                    <Stack direction="row" gap={5} mb={3}>
                      <Typography>Vuxen (35 kr)</Typography>
                      <ButtonGroup variant="contained">
                        <Button onClick={() => decrementTicket("adult")}>
                          -
                        </Button>
                        <Button variant="outlined" disableRipple>
                          {tickets.adult}
                        </Button>
                        <Button onClick={() => incrementTicket("adult")}>
                          +
                        </Button>
                      </ButtonGroup>
                    </Stack>
                    <Stack direction="row" gap={4.6} mb={5}>
                      <Typography>Rabatt (20 kr)</Typography>
                      <ButtonGroup variant="contained">
                        <Button onClick={() => decrementTicket("discount")}>
                          -
                        </Button>
                        <Button variant="outlined" disableRipple>
                          {tickets.discount}
                        </Button>
                        <Button onClick={() => incrementTicket("discount")}>
                          +
                        </Button>
                      </ButtonGroup>
                    </Stack>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={totalPrice === 0 ? true : false}
                      sx={{ borderRadius: 10 }}
                      onClick={getTickets}
                    >
                      Betala {totalPrice} kr
                    </Button>
                  </Box>
                )}

                {index === 1 && (
                  <Box>
                    <Typography
                      color="text.secondary"
                      variant="body1"
                      sx={{ mb: 5 }}
                    >
                      {step.description}
                    </Typography>
                    <EmbeddedCheckoutProvider
                      stripe={stripePromise}
                      options={stripeOptions}
                    >
                      <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{ borderRadius: 10, mt: 2 }}
                      onClick={(() => setActiveStep(0))}
                    >
                      Gå tillbaka
                    </Button>
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Snackbar
          open={alert.show}
          autoHideDuration={6000}
          onClose={() => {
            setAlert({
              text: "",
              success: false,
              show: false,
            });
          }}
        >
          <Alert
            onClose={() => {
              setAlert({
                text: "",
                success: false,
                show: false,
              });
            }}
            severity={alert.success ? "success" : "error"}
          >
            {alert.text}
          </Alert>
        </Snackbar>
      </CustomTabPanel>
    </div>
  );
}

export default PaymentTab;
