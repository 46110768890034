import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  TextField,
  Modal,
  Tabs,
  Tab,
  Container,
} from "@mui/material";
import MessageTab from "../components/admin/MessageTab";
import PaymentTab from "../components/admin/PaymentTab";

function Admin() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Auth states
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const [password, setPassword] = useState(null);

  const handleAuth = () => {
    if (password === "zeabuz<3zeam") {
      setOpen(false);
      setAuth(true);
    } else {
      setWrongPass(true);
    }
  };

  useEffect(() => {
    if (!auth) {
      setOpen(true);
    }
  }, [auth]);

  //Tab control
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        mt: isMobile ? "125px" : "175px",
        minHeight: "85vh",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Uppdatera skärmar" {...a11yProps(0)} />
          <Tab label="Onlinebetalning (test)" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <MessageTab value={value} />
      <PaymentTab value={value} />
      <Modal
        open={open}
        onClose={handleAuth}
        sx={{ backdropFilter: "blur(5px)" }}
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Inloggning
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Du måste vara en administratör för att komma åt denna sidan.
            Vänligen skriv in lösenordet för att fortsätta.
          </Typography>
          <TextField
            type="password"
            label="Lösenord"
            helperText={wrongPass ? "Fel lösenord" : ""}
            fullWidth
            error={wrongPass}
            margin="normal"
            onChange={(event) => {
              setPassword(event.target.value);
              if (wrongPass) {
                setWrongPass(false);
              }
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleAuth();
              }
            }}
          />
          <Button
            onClick={handleAuth}
            variant="contained"
            size="large"
            color="primary"
            sx={{ borderRadius: 10, mt: 2 }}
          >
            Logga in
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

export default Admin;
