import { Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HeroText({ northDeparture, southDeparture }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  return (
    <Stack
      spacing={4}
      sx={{
        maxWidth: 992,
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Box>
        <Typography variant="h3" component="h1" align="center">
          {t("intro1")}
        </Typography>
        <Typography variant="body1" align="center">
          {t("intro2")}
        </Typography>
      </Box>
      <Stack>
        <Typography
          variant="h6"
          align="center"
          fontWeight="bold"
          color={"white"}
        >
          {t("intro3")}
        </Typography>
        <Typography
          variant="h6"
          color={"white"}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
        >
          Kungsholmen:
          <Typography
            variant="h6"
            component="span"
            fontWeight="bold"
            color={"secondary.secondary"}
          >
            {northDeparture}
          </Typography>
        </Typography>
        <Typography
          variant="h6"
          color={"white"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            gap: 1,
          }}
        >
          Södermalm:
          <Typography
            variant="h6"
            component="span"
            fontWeight="bold"
            color={"secondary.secondary"}
          >
            {southDeparture}
          </Typography>
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={6} sx={{ mt: 5 }}>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => navigate("/departures")}
          sx={{
            borderRadius: 10,
            color: "#e0f5e3",
            borderColor: "#e0f5e3",
            "&:hover": {
              borderColor: "#e0f5e3",
              opacity: 0.75,
            },
          }}
        >
          {t("timetable")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => navigate("/ticket")}
          sx={{
            borderRadius: 10,
            color: "black",
            bgcolor: "#e0f5e3",
            "&:hover": {
              bgcolor: "#e0f5e3",
              opacity: 0.75,
            },
          }}
        >
          {t("ticketButton")}
        </Button>
      </Stack>
    </Stack>
  );
}

export default HeroText;
