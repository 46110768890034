import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Stack,
  alpha,
} from "@mui/material";
import { gray } from "../../App";
import SectionHeader from "../common/SectionHeader";
import ReactPlayer from "react-player/youtube";
import { motion } from "framer-motion";

const ferry = require("../../assets/Zeabuz-tech.jpg");
const zeabuz = require("../../assets/zeabuz_logo.png");
const torghatten = require("../../assets/Torghatten_logo.png");

function About() {
  const { t } = useTranslation();

  const navigateTechnology = () => {
    window.open("https://www.zeabuz.com/technology");
  };

  return (
    <Container
      id="about"
      sx={{
        py: { xs: 10, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 10 },
      }}
    >
      <SectionHeader
        chip={"aboutChip"}
        title={"aboutTitle"}
        text={"aboutText"}
      />
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: -10,
          },

          visible: {
            opacity: 1,
            y: 0,
          },
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <Grid container spacing={6} rowGap={{ sx: 0, sm: 10 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ width: "100%" }}
            order={{ xs: 2, md: 1 }}
          >
            <Card
              variant="outlined"
              sx={{
                width: "95%",
                pointerEvents: "none",
                p: { xs: 0, sm: 3 },
                border: `1px solid ${alpha(gray[200], 0.8)}`,
                borderRadius: 10,
                background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
              }}
            >
              <ReactPlayer
                url="https://youtu.be/YJK33ncifgM"
                playing={true}
                loop={true}
                volume={0}
                width={"100%"}
                height={295}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Typography component="h2" variant="h4" color="text.primary">
              {t("missionTitle")}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              {t("missionText")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 3, md: 3 }}>
            <Typography component="h2" variant="h4" color="text.primary">
              {t("ferryTitle")}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              {t("ferryText")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={navigateTechnology}
              sx={{ borderRadius: 10 }}
            >
              {t("ferryButton")}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ width: "100%" }}
            order={{ xs: 4, md: 4 }}
          >
            <img
              src={ferry}
              alt="södermalm"
              style={{ maxWidth: "100%", borderRadius: "20px" }}
            />
          </Grid>
        </Grid>
      </motion.div>
      <Box
        sx={{
          width: { sm: "100%", md: "75%" },
          textAlign: "left",
        }}
      >
        <Typography
          align="center"
          component="h2"
          variant="h4"
          color="text.primary"
        >
          {t("backgroundTitle")}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t("backgroundText")}
        </Typography>
      </Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-around"}
        width={"100%"}
        alignItems={"center"}
        gap={2}
      >
        <Card
          variant="outlined"
          sx={{
            width: { xs: "80%", sm: "300px" },
            py: { xs: 2, sm: 3.5 },
            px: { xs: 2, sm: 3 },
            ml: { xs: 0, sm: 3 },
            border: `1px solid ${alpha(gray[200], 0.8)}`,
            borderRadius: 10,
            background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
            "&:hover": {
              borderColor: "#203236",
              boxShadow: `0 0 5px ${gray[50]}`,
            },
          }}
        >
          <CardActionArea href="https://torghatten.no" target="_blank">
            <CardMedia
              component="img"
              height="100%"
              image={torghatten}
              alt="torghatten"
            />
          </CardActionArea>
        </Card>

        <Card
          variant="outlined"
          sx={{
            width: { xs: "55%", sm: "200px" },
            p: { xs: 2, sm: 3 },
            border: `1px solid ${alpha(gray[200], 0.8)}`,
            borderRadius: 10,
            background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
            "&:hover": {
              borderColor: "#203236",
              boxShadow: `0 0 5px ${gray[50]}`,
            },
          }}
        >
          <CardActionArea href="https://zeabuz.com" target="_blank">
            <CardMedia
              component="img"
              height="100%"
              image={zeabuz}
              alt="zeabuz"
            />
          </CardActionArea>
        </Card>
      </Stack>
    </Container>
  );
}

export default About;
