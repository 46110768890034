import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NotificationIcon from "remixicon-react/NotificationLineIcon";
import FacebookIcon from "remixicon-react/FacebookFillIcon";
import InstagramIcon from "remixicon-react/InstagramFillIcon";
import { Divider } from "@mui/material";

const logoStyle = {
  width: "140px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "} Zeam {new Date().getFullYear()}
    </Typography>
  );
}

const zeamWater = require("../../assets/Zeam_Water.png");

export default function Footer() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const navigateSubscribe = () => {
    navigate("/subscribe");
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
     });
  };
  return (
    <Box sx={{ zIndex: 1, position: "relative", backgroundColor: "white" }}>
      <Divider />
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <Box sx={{ ml: "-15px" }}>
              <img src={zeamWater} style={logoStyle} alt="logo of sitemark" />
            </Box>
            <Typography variant="body2" fontWeight="bold" gutterBottom>
              {t("subscribeTitle")}
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {t("subscribeText")}
            </Typography>

            <Button
              onClick={navigateSubscribe}
              variant="contained"
              color="primary"
              startIcon={<NotificationIcon />}
              sx={{ flexShrink: 0, borderRadius: 10 }}
            >
              {t("subscribeButton")}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
          {t("menuService")}
          </Typography>
          <Link color="text.secondary" href="/departures">
          {t("menuDepartures")}
          </Link>
          <Link color="text.secondary" href="/ticket">
          {t("menuTickets")}
          </Link>
          <Link color="text.secondary" href="/find-us">
          {t("menuFind")}
          </Link>
          <Link color="text.secondary" href="/faq">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
          {t("menuCompany")}
          </Typography>
          <Link color="text.secondary" href="/about">
          {t("menuAbout")}
          </Link>
          <Link color="text.secondary" href="https://torghatten.no/career" target="_blank">
          {t("menuCareers")}
          </Link>
          <Link color="text.secondary" href="https://www.mynewsdesk.com/se/zeam" target="_blank">
          {t("menuNews")}
          </Link>
          {/* <Link color="text.secondary" href="https://news.cision.com/zeam/">
          {t("menuInternationalNews")}
          </Link> */}
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
          {t("menuLegal")}
          </Typography>
          <Link color="text.secondary" href="/privacy">
            Privacy Policy
          </Link>
          <Link color="text.secondary" href="/contact">
          {t("menuContact")}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" href="#">
            Privacy Policy
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.facebook.com/profile.php?id=100089544554710"
            target="_blank"
            aria-label="Facebook"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/zeam_mobility/"
            target="_blank"
            aria-label="Insta"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
    </Box>
  );
}
