import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

function SubscribeWidget() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
    >
        <iframe
          width={isMobile ? "100%" : "700"}
          height={isMobile ? 1200 : 1000}
          style={{marginTop: isMobile ? 0 : "10vh"}}
          src="https://66bd2aad.sibforms.com/serve/MUIFAI8sGYcqdPGBAHw955GmJp2qVxL8PHIWQT8EF8GjMmo6Mw7VvNgn9Cbs2qgzMYFKptvr8mkJVMO6EUd6nfdbE9scdomvnAhNlet6z3_fthsqG3BG1rzS8oyNjWownmVZdkfitzGqhl-N8B9gq-nLpJkIqooPQBpRChSXhIuA4w3FeM4POHv8myPa4OBnKZAKDmShRp7PkT2j"
          frameborder="0"
          scrolling="auto"
          allowfullscreen
          title="Brevo iframe"
        ></iframe>
    
    </Box>
  );
}

export default SubscribeWidget;
