import React from "react";
import { useTranslation } from "react-i18next";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import { Icon } from "leaflet";
import { Card, Container, alpha } from "@mui/material";
import { Link } from "react-router-dom";
import { gray } from "../../App";
import SectionHeader from "../common/SectionHeader";
import { motion } from "framer-motion";

const customPin = require("../../assets/customPin.png");

function Map() {
  const { t } = useTranslation();
  const polyline = [
    [59.326227, 18.044069],
    [59.321313, 18.050621],
  ];
  return (
    <Container
      id="find-us"
      sx={{
        py: { xs: 10, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <SectionHeader chip={"mapChip"} title={"mapTitle"} text={"mapText"} />

      <Card
        variant="outlined"
        sx={{
          width: { xs: "80%", sm: "90%" },
          p: { xs: 4, sm: 6 },
          border: `1px solid ${alpha(gray[200], 0.8)}`,
          borderRadius: 10,
          background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
        }}
      >
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: -10,
            },

            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <MapContainer
            center={[59.324027, 18.048618]}
            zoom={14}
            scrollWheelZoom={true}
            style={{ height: "60vh", width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://api.mapbox.com/styles/v1/carl-p/clewu78e6005401mwek611qji/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2FybC1wIiwiYSI6ImNsMjdoMXBvdDAwaWkzaW5ycmRyeHh5cTkifQ.YmJWHpigXfJWzuJWx_rP_g"
            />
            <Marker
              position={[59.326227, 18.044069]}
              icon={
                new Icon({
                  iconUrl: customPin,
                  iconSize: [60, 40],
                  iconAnchor: [30, 41],
                })
              }
            >
              <Popup>
                {t("northTitle")}
                <br />
                <Link href="https://www.google.com/maps/place/Zeam/@59.3233908,18.0470097,15.31z/data=!4m6!3m5!1s0x465f77f9ad0eb3ed:0xba97c30349d3ee2d!8m2!3d59.3263866!4d18.0441101!16s%2Fg%2F11kq0y1xhs?entry=ttu">
                  {t("directions")}
                </Link>
              </Popup>
            </Marker>
            <Marker
              position={[59.321313, 18.050621]}
              icon={
                new Icon({
                  iconUrl: customPin,
                  iconSize: [60, 40],
                  iconAnchor: [30, 41],
                })
              }
            >
              <Popup>
                {t("southTitle")}
                <br />
                <Link href="https://www.google.com/maps/place/S%C3%B6der+M%C3%A4larstrand/@59.3215002,18.0502997,18.52z/data=!4m6!3m5!1s0x465f77dd9478cbed:0x2b58125ff873896!8m2!3d59.32131!4d18.050757!16s%2Fg%2F11vl52x8j3?entry=ttu">
                  {t("directions")}
                </Link>
              </Popup>
            </Marker>
            <Polyline pathOptions={{ color: "#203236" }} positions={polyline}>
              <Popup>
                {t("crossing")}
                <br />
                {t("length")}
                <br />
                {t("time")}
              </Popup>
            </Polyline>
          </MapContainer>
        </motion.div>
      </Card>
    </Container>
  );
}

export default Map;
