import React, { useEffect, useState } from "react";
import BottomPart from "../components/screens/BottomPart";
import TopPart from "../components/screens/TopPart";
import getStoppage from "../functions/getStoppage";
import getSchedule from "../functions/getSchedule";

function NorthScreen() {
  const goingTo = "Södermalm";
  const [departure, setDeparture] = useState("laddar tider...");
  const [stoppage, setStoppage] = useState({
    stoppage: false,
    messageEn: "",
    messageSv: "",
    severity: "error",
  });

  useEffect(() => {
    getStoppage(setStoppage);
    getSchedule(setDeparture, "Norr Mälarstrand");
    const calcDeparture = setInterval(() => {
      getStoppage(setStoppage);
      getSchedule(setDeparture, "Norr Mälarstrand");
    }, 60000);
    return () => clearInterval(calcDeparture);
  }, []);
  
  return (
    <>
      <TopPart
        stoppage={stoppage}
        setStoppage={setStoppage}
        departure={departure}
        goingTo={goingTo}
      />
      <BottomPart />
    </>
  );
}

export default NorthScreen;
