import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NotificationIcon from "remixicon-react/NotificationLineIcon";
import { motion } from "framer-motion";

const background = require("../../assets/background.png");

function CTA() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const navigateSubscribe = () => {
    navigate("/subscribe");
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <Box
      id="highlights"
      sx={{
        py: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#203236",
        position: "relative",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: -20,
            },

            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 1, delay: 0.1 }}
          viewport={{ once: true }}
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            width={"100%"}
            justifyContent={"space-around"}
            gap={3}
          >
            <Box
              sx={{
                width: { sm: "100%", md: "60%" },
                textAlign: { sm: "left", md: "left" },
              }}
            >
              <Typography component="h2" variant="h4">
                {t("subscribeTitle")}
              </Typography>
              <Typography variant="body1" sx={{ color: "grey.400" }}>
                {t("subscribeText")}
              </Typography>
            </Box>

            <Button
              onClick={navigateSubscribe}
              variant="contained"
              color="secondary"
              startIcon={<NotificationIcon />}
              sx={{ borderRadius: 10, width: { sm: "20%" }, fontSize: 17 }}
            >
              {t("subscribeButton")}
            </Button>
          </Stack>
        </motion.div>
      </Container>
    </Box>
  );
}

export default CTA;
