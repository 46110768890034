import { useState, useEffect } from "react";
import { Box, Link, Divider } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Axios from "axios";
import GetDeparture from "../components/GetDeparture";
import Hero from "../components/hero";
import Value from "../components/value";
import Map from "../components/map";
import About from "../components/about";
import Contact from "../components/contact";
import Service from "../components/service";
import CTA from "../components/CTA";
import FAQ from "../components/FAQ";
import usePageViews from "../hooks/usePageViews";

function Home() {
  usePageViews();
  const { t } = useTranslation();
  const [northDeparture, setNorthDeparture] = useState("Laddar tider...");
  const [southDeparture, setSouthDeparture] = useState("Laddar tider...");

  const getSchedule = () => {
    Axios({
      method: "GET",
      url: "https://zeam-screen-default-rtdb.europe-west1.firebasedatabase.app/schedule2.json",
    })
      .then((res) => {
        const newSchedule = {
          sunday: res.data.sunday,
          monday: res.data.monday,
          tuesday: res.data.tuesday,
          wednesday: res.data.wednesday,
          thursday: res.data.thursday,
          friday: res.data.friday,
          saturday: res.data.saturday,
        };

        let newSouthDeparture = GetDeparture(newSchedule, "Söder Mälarstrand");
        setSouthDeparture(newSouthDeparture);

        let newNorthDeparture = GetDeparture(newSchedule, "Norr Mälarstrand");
        setNorthDeparture(newNorthDeparture);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getSchedule();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Zeam | Making urban life flow | Stockholm</title>
        <meta
          name="description"
          content="Zeam is operating the world's first commercial electric and autonomous passenger ferry since summer 2023"
        />
      </Helmet>
      <Hero southDeparture={southDeparture} northDeparture={northDeparture} />
      <Box sx={{ zIndex: 1, position: "relative", backgroundColor: "white" }}>
        <Service />
        <Value />
        <Map />
        <CTA />
        <About />
        <Divider />
        <FAQ />
        <Divider />
        <Contact />
      </Box>
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="cookieConsent"
        style={{ background: "#203236", color: "#ffd6dd" }}
        buttonStyle={{
          color: "#046c5a",
          fontSize: "13px",
          background: "#e0f5e3",
          width: 75,
        }}
        expires={150}
      >
        {t("cookies")}
        <Link
          href="/privacy"
          style={{ fontSize: "10px", color: "#ffd6dd", cursor: "pointer" }}
        >
          {t("cookieButton")}
        </Link>
      </CookieConsent>
    </Box>
  );
}

export default Home;
