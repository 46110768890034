import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const usePageViews = () => {
  let location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview', 
        page: location.pathname + location.search, 
      },
    });
  }, [location]); 
}

export default usePageViews