function GetDeparture(newSchedule, side) {
    let now = new Date();
    let today = now.getDay();
    let time = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    //get todays schedule
    const scheduleValues = Object.values(newSchedule);
    let todaysSchedule = scheduleValues[today];
    var result = todaysSchedule.filter((obj) => {
      return obj.from === side;
    });
    const departures = result.map(({ departure }) => departure);

    //get tomorrows schedule
    let tomorrowSchedule = scheduleValues[today + 1]
    ? scheduleValues[today + 1]
    : scheduleValues[0];
    var resultTomorrow = tomorrowSchedule.filter((obj) => {
      return obj.from === side;
    });
    const departuresTomorrow = resultTomorrow.map(({ departure }) => departure);
    let i = 0;
    while (departures[i] < time) {
      var displayDeparture = departures[i + 1]
        ? departures[i + 1]
        : departuresTomorrow[0];
      i++;
    }
    let newDeparture = displayDeparture.toString();
  return (
    newDeparture
  )
}

export default GetDeparture