import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import Map from '../components/map';

function FindScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
      <title>Zeam | Find Us</title>
      <meta
        name="description"
        content="This page shows the location of Estelle on a map"
      />
    </Helmet>
    <Map />
  </>
);
}

export default FindScreen