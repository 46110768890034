import React from "react";
import { useTranslation } from "react-i18next";
import BatteryIcon from "remixicon-react/BatterySaverLineIcon";
import TimeIcon from "remixicon-react/TimeLineIcon";
import VolumeIcon from "remixicon-react/VolumeDownLineIcon";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";

const background = require("../../assets/background.png");

function Value() {
  const { t } = useTranslation();
  const items = [
    {
      icon: <BatteryIcon color="#ffd6dd" size={60} />,
      title: t("greenTitle"),
      description: t("greenText"),
    },
    {
      icon: <TimeIcon color="#ffd6dd" size={60} />,
      title: t("timeTitle"),
      description: t("timeText"),
    },
    {
      icon: <VolumeIcon color="#ffd6dd" size={60} />,
      title: t("relaxTitle"),
      description: t("relaxText"),
    },
  ];
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#203236",
        position: "relative",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: -20,
            },

            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 1, delay: 0.1 }}
          viewport={{ once: true }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            <Typography component="h2" variant="h4">
              {t("valueTitle")}
            </Typography>
            <Typography variant="body1" sx={{ color: "grey.400" }}>
              {t("valueText")}
            </Typography>
          </Box>
          <Grid container spacing={2.5}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Stack
                  direction="column"
                  color="inherit"
                  spacing={1}
                  useFlexGap
                  sx={{
                    p: 3,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: { sm: "left", md: "center" },
                  }}
                >
                  <Box>{item.icon}</Box>

                  <Typography variant="subtitle1" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
}

export default Value;
