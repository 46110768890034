import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import SectionHeader from "../common/SectionHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TicketPrices from "./TicketPrices";
import Checkout from "./Checkout";

function Prices() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const navigateUser = () => {
    navigate("/user");
  };
  return (
    <Container
      id="prices"
      sx={{
        py: { xs: 10, sm: 16 },
      }}
    >
      <SectionHeader
        chip={"priceChip"}
        title={"priceTitle"}
        text={"priceText"}
      />
      <Box>
        <Checkout />
          <Typography variant="subtitle1" fontWeight="bold">
            {t("priceTitle1")}
          </Typography>
          <Typography variant="body1" color="text.secondary" marginBottom={3}>
            {t("priceText1")}
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {t("priceTitle2")}
          </Typography>
          <Typography variant="body1" color="text.secondary" marginBottom={3}>
            {t("priceText2")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ borderRadius: 10 }}
            onClick={navigateUser}
          >
            {t("priceButton")}
          </Button>
      </Box>
    </Container>
  );
}

export default Prices;
