import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import User from "../components/user";
import { motion } from "framer-motion";

function UserScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Zeam | User</title>
        <meta
          name="description"
          content="Register for discounts, get receipts, and see travel history."
        />
      </Helmet>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: -20,
          },

          visible: {
            opacity: 1,
            y: 0,
          },
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.1 }}
        viewport={{ once: true }}
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <User />
      </motion.div>
    </>
  );
}

export default UserScreen;
